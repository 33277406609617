/*
 * File: CellFormatting.js
 * Project: parkez-dashboard
 * File Created: Thursday, 9th April 2020 3:51:11 pm
 * Author: Rahul (rahul@valetez.com)
 * -----
 * Last Modified: Sunday, 17th May 2020 7:40:27 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * cell component for the report tables
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ToolTipIcon from 'components/ToolTipIcon'
import GetAppIcon from "@material-ui/icons/GetApp";
import { useState } from "react";

const useStyles = makeStyles((theme) =>({
    root: {
        fontFamily:'Roboto'
    },
    header : {
        background : theme.palette.secondary.main,
        color:'white'
    },
    cell : {
    },
    button:{
        marginBottom:5
    }
}))

const numFormatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 6 })
const amountFormatter = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2,maximumFractionDigits: 2 })
// function checkImage (src, good, bad) {
//     var img = new Image();
//     img.onload = good; 
//     img.onerror = bad;
//     img. src = src;
//   }
function ImgViewer(props){
    // const [sucessImg,setSucessImg] = useState([])

    // useEffect(()=>{
    //     if(props.data.link != null && props.data.link && props.data.link.length > 0 ){
            
    //         let links = props.data.link
    //         let imgs = _.cloneDeep(sucessImg)
    //         links.map(link=>{
    //             checkImage(link,()=>{
    //                 imgs.push(link)
    //                 setSucessImg(imgs)
    //             })
    //         })
    //     }
    // },[props.data.link])
    return(
        <>
        {
            (props.data.link != null && props.data.link && props.data.link.length > 0 )
                ? <div>
                    <Button onClick={() => {props.handleImageDialog(props.data.link)}}  variant="contained" className="jr-btn bg-light-green text-white">
                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                        <span>View</span>
                    </Button>                                
                </div>
                : 'N/A'
        }
        </>
    )
}
const ActionBtns = (props)=>{
    console.log("ActionBtns",props)
    return(
        <>
            {
                props.data.updateAmount &&
                    <Button color='primary' onClick={props.updateParkingAmount} variant='contained' className="jr-btn" >update Amount</Button>
            } 
        </>
    )
}

const ActionButton = (props)=>{
    console.log("CloseBtn - ", props)
    const [disable, setDisable] = useState(false);

    return(
        <Button color='primary' onClick={() => { 
            props.handleClick(props.data, setDisable)}
        } 
        variant='contained' 
        className="jr-btn" 
        disabled={disable} >
            {props.title}
        </Button>
    )
}
function TableCellConditional(props) {     
    const classes = useStyles();
    console.log('TableCellConditional-props',props);
    

    if(typeof props.data == 'object'){
        return (
            Object.keys(props.data).map((item, i) =>{
                console.log('TableCellConditional-itme',item);
                return(
                    item.includes("hidden") ?
                                    null : typeof props.data[item] == 'object' && props.data[item] !=null
                        // item is an object
                        ? props.data[item].isCheckpoint
                            // item is a checkpoint
                            ? <TableCell key={`checkpoint-${i}`} classes={{ head:classes.header,root:classes.root }}  size="small" align="right">                    
                                <ExpansionPanel style={{width:"80%",float:"right"}}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography >{props.data[item].title + ' - has checkpoints'}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Typography gutterBottom noWrap>
                                        {props.data[item].checkpointData[0].route.map((n,index) => {
                                            return(<div style={{display:"block"}}>
                                            <p>{props.data[item].checkpointData[0].route[index].entry_time+'('+props.data[item].checkpointData[0].route[index].checkpoint+')'}</p>
                                            </div>)
                                        })}
                                    </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </TableCell> 
                            // item is not a checkpoint
                            : item === 'images' 
                                // item is an image
                                ? <TableCell key={`images-${i}`}  classes={{ head:classes.header,root:classes.root }}  size="small" align="left">
                                    <ImgViewer 
                                        data={props.data[item]}
                                        handleImageDialog={(imgs)=>props.handleImageDialog(imgs)}
                                    />
                                </TableCell>
                            // item is actions
                             : item === 'actions' 
                                // item is an image
                                ? <TableCell key={`actions-${i}`}  classes={{ head:classes.header,root:classes.root }}  size="small" align="left">
                                        {props.data[item].updateAmount && <ActionBtns data={props.data[item]} updateParkingAmount={()=>props.updateParkingAmount(props.data)} />}
                                        {props.data[item].downloadReceipt && <ToolTipIcon title="Download Receipt" icon={<GetAppIcon/>} onClick={()=>props.downloadReceipt(props.data)} />}
                                        {props.data[item].deleteMaintenance && <ToolTipIcon title="Remove From Maintenance" icon={<DeleteIcon/>} onClick={()=>props.deleteMaintenance(props.data)} />}
                                        {props.data[item].downloadReport && <ToolTipIcon title="Download Report" icon={<GetAppIcon/>} onClick={()=>props.downloadReport(props.data)} />}
                                        {props.data[item].closeBooking && <ActionButton title="Close Booking" data={props.data} handleClick={props.closeBooking} />}
                                 </TableCell>
                                // item is not an image
                                : item === 'button' ? 
                                // item is an action button
                                <TableCell key={`button-${i}`}  classes={{ head:classes.header,root:classes.root }}  size="small" align="left">
                                <div> 
                                    <Button onClick={() => {props.showOrderDetails(props.data)}}  variant="contained" className="jr-btn bg-light-green text-white">
                                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                        <span>View</span>
                                    </Button>   
                                                                 
                                </div>
                                </TableCell>
                                :  props.data[item].style.rowspan === 0 || props.data[item].style.colspan === 0 
                                    // item has rowSpan === 0 
                                    ? "" 
                                    // item has rowSpan !== 0 
                                    : <TableCell key={`rowspan-${i}`} style={props.data[item].style.style} classes={{ head:classes.header,root:classes.root }}  size="small" align={((isNaN(props.data[item].title)) ? "left" : "right")} rowSpan={props.data[item].style.rowspan} colspan={props.data[item].style.colspan ? props.data[item].style.colspan : 1}>
                                        {isNaN(props.data[item].title) ? props.data[item].title :numFormatter.format(props.data[item].title)}
                                    </TableCell>
                        // item is not an object
                        : item === 'renew' ?
                        <TableCell key={`renew-${i}`} classes={{ head:classes.header,root:classes.root }}  size="small" align="left">
                        <div>
                            {(props.data[item] != 'false') && <Button onClick={() => {props.renewSubscription(props.data)}}  variant="contained" className="jr-btn bg-light-green text-white">
                                <span>Renew</span>
                            </Button>  
                            } 
                            {(props.data['update_type_hidden']==='user_subscription' || props.data['update_type_hidden']==='user_client' || props.data['update_type_hidden']==='client_subscription') && props.data['activate_hidden'] == 1 &&
                                <ToolTipIcon title="Edit Subscription" icon={<EditIcon/>} onClick={()=>props.onEdit(props.data)} />
                            }
                            {props.data['update_type_hidden']==='user_subscription' && props.data['activate_hidden'] == 1 &&  props.downloadInvoice &&
                                <ToolTipIcon title="Download Invoice" icon={<GetAppIcon/>} onClick={()=>props.downloadInvoice(props.data)} />
                            }
                            {!(props.data['update_type_hidden']==='client_subscription' && props.data['activate_hidden'] == 0)&&
                                <FormControlLabel
                                    control={<Switch color="primary" name="enabled" 
                                    checked={parseInt(props.data['active_hidden'])>0} onClick={(e) => props.onEnable(props.data, e.target.checked)}/>}
                                />
                            }
                            {/* {props.data['update_type_hidden']==='user_subscription' && props.data['activate_hidden'] == 1 && <Button color={'primary'} onClick={() => {props.updteVehicleNo(props.data)}}  variant="contained" className={`jr-btn  text-white ${classes.button}`}>
                                <span>Update Vehicle No</span>
                            </Button>  
                             }*/}
                            {props.data['tag_type']==='nfc' && props.data['activate_hidden'] == 1 && props.data['update_amount_btn_hidden'] == true &&
                            <Button color={'primary'} onClick={() => {props.updateAmount(props.data)}}  variant="contained" className={`jr-btn  text-white  ${classes.button}`} disabled={props.data['update_amount_hidden'] == 0}>
                                <span>Update LoC Amount</span>
                            </Button>  
                             }
                            
                        </div> </TableCell>: 
                        <TableCell key={`Norenew-${i}`} classes={{ head:classes.header,root:classes.root }} size="small" align={((isNaN(props.data[item]) || props.data[item] == null) && item!=='count' && item!=='entry_time' && item!=='exit_time' && item!=='spent_time' && item!=='vehicle_no' && item!=='table_name' && item!=='id' && item!=='amount' && item!=='serial_no' && item!=='start' && item!=='end' && item!=='pallet' && item!=='sub_location'  ? "left" : "right")} >
                            {
                            (item === 'exit_time' || item === 'exit_guard') && (props.data[item] === '-' || props.data[item] === ' ' || props.data[item] === null) 
                                ? <div className="badge text-white bg-success">Active</div> 
                                //item is an exit comment
                                : (item === 'exit_comments' || item === 'comments')?
                                    props.data[item] != null && props.data[item]  
                                    ? <div>
                                        <Button onClick={() => {props.handlePopUpDialog(item === 'exit_comments'?'Exit Comments':'Comments',props.data[item])}}  variant="contained" className="jr-btn bg-light-green text-white">
                                            <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                            <span>View</span>
                                        </Button>                                
                                    </div>
                                    : 'N/A' 
                                : item==='vehicle_no' || item==='phone' || item==='txn_id' || item==='token' || item==='session_id' || item==='auto_tag' || item==='S.N.' || isNaN(props.data[item]) ? props.data[item] : item === 'dinein_amount' ? amountFormatter.format(props.data[item]) : numFormatter.format(props.data[item]) }
                        </TableCell>
                        
                    )
            })
        )
    } else {
            return <TableCell classes={{ head:classes.header,root:classes.root }} size="small" align={(isNaN(props.data) ? "left" : "right")}>{props.data}</TableCell>
    }
}
function TableHeadCellConditional(props){
    const classes = useStyles();
    return <TableCell classes={{ head:classes.header,root:classes.root }} size="small" align={(props.data.style.align)}>{props.data.title}</TableCell>

}
function CellFormatting(props){
    if(props.type === 'body')
        return(TableCellConditional(props))
    else 
        return(TableHeadCellConditional(props))

}

export default CellFormatting;
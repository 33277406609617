import React,{useState,useEffect} from 'react';
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'


const useStyles = makeStyles( (theme) =>(
    {
        mapButton:{
            marginTop:'32px',
            color:theme.palette.secondary.light,
        },
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }
))

const Printer = (props)=> {
    console.log("Printer props are", props)
    const classes = useStyles();
    const vendors = ["Epson", "NGX", "EP802-TM", "MobiOcean", "Z91", "T1-mini","Pinelabs", "iMin", "Custom"]
    const initialPrinter = {
        mac_address: "",
        name: "",
        serial_no: "",
        vendor: "",
        location: "",
        pl_id: ""
    }
    const [error, setError] = useState({});

    const [printer, setPrinter] = useState(initialPrinter)

    useEffect(() => {
        if(props.printer) {
            if(props.printer.name!==printer.name) {
               console.log("printer", props.printer)
               setPrinter({
                name: props.printer.name,
                id: props.printer.hidden_id,
                pl_id: props.printer.hidden_parking_id,
                mac_address: props.printer.mac_address,
                serial_no: props.printer.serial_no,
                vendor: props.printer.vendor,
                location: props.printer.location
            }) 
            } else if (null != printer.name) {
                setPrinter(initialPrinter)
            }
        }
    },[props.printer])

    useEffect(()=>{
        if(props.refreshForm===true){
            setPrinter(initialPrinter);
            props.setRefresh();
        }
    },[props.refreshForm])

    const handleInputChange = (e,val) => {                
        const {name, value} = e.target
        if(name) { 
            console.log("input change", name,value)
            setPrinter({...printer, [name]: value})
        } else {
            console.log(val)
            if(val.name) {
              setPrinter({...printer, 'pl_id': val.id, 'location': val.identifier})  
            } else {
              setPrinter({...printer, 'vendor': val})  
            }           
        }
    }

    const onReset = () => {
        setPrinter(initialPrinter)
        props.onReset()
    }

    const handleSubmit = ()=>  {
        if (!printer.mac_address) {
          setError({ ...error, ["mac_address"]: "Required" });
        } else if (!printer.name) {
          setError({ ...error, ["name"]: "Required" });
        } else if (!printer.serial_no) {
          setError({ ...error, ["serial_no"]: "Required" });
        } else if (!printer.vendor) {
          setError({ ...error, ["vendor"]: "Required" });
        } else if (!printer.pl_id) {
          setError({ ...error, ["pl_id"]: "Required" });
        } else {
          props.onSubmit(printer);
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-xl-2"> 
                   <InputLabel shrink={true} htmlFor="name" className={classes.label}>Mac Address</InputLabel> 
                   <TextField name="mac_address" error={error.mac_address?true:false} helperText={error.mac_address} margin="none" onChange={handleInputChange} value={printer.mac_address} fullWidth/> 
                </div>
                <div className="col-lg-3 col-xl-2"> 
                   <InputLabel shrink={true} htmlFor="name" className={classes.label}>Name</InputLabel> 
                   <TextField name="name" error={error.name?true:false} helperText={error.name} margin="none" onChange={handleInputChange} value={printer.name} fullWidth/> 
                </div>
                <div className="col-lg-3 col-xl-2"> 
                   <InputLabel shrink={true} htmlFor="name" className={classes.label}>Serial No</InputLabel> 
                   <TextField name="serial_no" error={error.serial_no?true:false} helperText={error.serial_no} margin="none" onChange={handleInputChange} value={printer.serial_no} fullWidth/> 
                </div>

                <div className="col-lg-3 col-xl-3">
                    <Autocomplete
                        value = {printer.vendor ? printer.vendor : null}
                        id = "vendor"
                        name = "vendor"
                        style={{marginTop:0}}
                        onChange={handleInputChange}
                        options = {vendors}
                        getOptionLabel={(option) => option}
                        error={error.vendor?true:false} helperText={error.vendor}
                        renderInput={(params) => (
                            <React.Fragment>
                                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Vendor</InputLabel>
                                    <TextField name="vendor" {...params} margin="none" />
                            </React.Fragment> 
                        )}
                    >

                    </Autocomplete>
                </div> 

                <div className="col-lg-3 col-xl-3">
                <Autocomplete
                    value = {printer.location ? _.find(props.options,{identifier:printer.location}) : null}
                    id = "location"
                    name = "location"
                    style={{marginTop:0}}
                    onChange={handleInputChange}
                    options = {props.options}
                    getOptionLabel={(option) => option.identifier}
                    error={error.pl_id?true:false} helperText={error.pl_id}
                    renderInput={(params) => (
                        <React.Fragment>
                                <InputLabel shrink={true} htmlFor="name" className={classes.label}>Location</InputLabel>
                                <TextField name="locationCity" {...params} margin="none" />
                        </React.Fragment> 
                    )}
                >

                </Autocomplete>
                </div>
             </div>

            <div className="row mt-2">   
                <div className="col-lg-6 col-sm-12 col-12">                    
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={handleSubmit}> Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" onClick={onReset}>Reset</Button></Box>
                    </Box>
                </div> 
            </div>  
        </div>
    )
}

export default Printer
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, connect, FieldArray, useFormikContext } from "formik";
import {
  Button,
  Box,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import ContainerHeader from "components/ContainerHeader";
import RemoveIcon from '@material-ui/icons/Remove'
import API from "util/Api";
import _ from "lodash";
import * as Yup from "yup";
import moment from "moment";
import SnackBar from "components/SnackBar";

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: "10px 0",
  },
  title: {
    marginBottom: 10,
  },
  field: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const SubscriptionField = connect((props) => {
  console.log("subsc-field-props", props);
  const classes = useStyles();
  const values = _.cloneDeep(props.formik.values);

  return (
    <>
      {props.section.key !== "vehicleDetails" ? (
        <div className="row mb-4">
          {props.section.fields.map((field) => (
            <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField
                field={{
                  ...field,
                  ...{ name: `${props.section.key}.${field.key}` },
                  ...((field.key === 'startDate' || field.key === 'endDate') && {
                    minDate: field.key === 'endDate' ? moment(values.subscriptionDetails.startDate).add(1, 'days') : new Date('2016-01-01'),
                    maxDate: field.key === 'startDate' ? moment(values.subscriptionDetails.endDate).subtract(1, 'days') : new Date('2027-01-01')
                  })
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <Typography
                className={classes.title}
                varient={"h5"}
                component={"h5"}
              >
                {props.section.title}
              </Typography>
            </div>
          </div>
          <FieldArray
            name={props.section.key}
            render={({ arrayHelpers, remove }) => (
              <>
                {values[props.section.key].map((item, index) => (
                  <div className="row">
                    <div className="row_form_fields">
                      {props.section.fields.map((field) => (
                        <div
                          className="col-md-3 col-12 w-100"
                          key={`field-${field.key}`}
                        >
                          <FormField
                            field={{
                              ...field,
                              ...{
                                name: `${props.section.key}[${index}].${field.key}`,
                              },
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      {values.vehicleDetails.length > 1 &&
                        <IconButton edge="start" color="inherit" onClick={(e) => remove(index)} aria-label="remove" disabled={values.vehicleDetails.length <= 1}>
                          <RemoveIcon color="error" />
                        </IconButton>
                      }
                    </div>
                  </div>
                ))}
                {/* <IconButton aria-label="Add New Vehicle" color="primary" onClick={() => arrayHelpers.push('')} >
        <AddIcon />
      </IconButton> */}
              </>
            )}
          />
        </>
      )}
    </>
  );
});

const SubscriptionSections = connect((props) => {
  const values = _.cloneDeep(props.formik.values);
  console.log("subsc-config-props", props);
  const { isSubmitting } = useFormikContext();

  return (
    <CardBox styleName="col-12" heading="">
      <Form>
        {props.config.map(
          (section, index) =>
            values[section.key] &&
            Object.keys(values[section.key]).length > 0 && (
              <SubscriptionField section={section} key={`section-${index}`} />
            )
        )}
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {" "}
                  Submit{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset">
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
  );
});

const EditSubscription = (props) => {
  console.log("edit-subsc-props", props);
  const { subscrcriptionId, subsType, locName, locId } = useParams()
  const [config, setConfig] = useState();
  const [initialValues, setInitialValues] = useState({})
  const [refreshSubscription, setRefreshSubscription] = useState(true);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = (initials) => Yup.object().shape({
    subscriptionDetails: Yup.object().shape({
      firstName: Yup.string().required("Required"),
      clientId: Yup.string().required("Required"),
      ...(initials.subscriptionDetails.userSubscriptionId && { // validation will work only in case of user subsc
        startDate: Yup.date().required("Required"),
        endDate: Yup.date().required("Required")
      })
    }),
    vehicleDetails: Yup.array().of(
      Yup.object().shape({
        vehicleType: Yup.string().required("Required"),
        vehicleNo: Yup.string().required("Required").min(5, "Invalid vehicle number").max(22, "Invalid vehicle number")
      })
    )
  });
  const updateSubscription = (e, { setSubmitting }) => {
    console.log("submit-data", e);
    setSubmitting(true);
    API.put("subscription", JSON.stringify({ ...e, type: subsType }))
      .then((res) => {
        setInitialValues({ ...e });
        setSubmitting(false);
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
        setRefreshSubscription(true);
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          msg: err?.response?.data?.msg
            ? err.response.data.msg
            : "Something went wrong",
          type: "error",
        });
        setSubmitting(false);
        console.log("catch");
      });
  };
  useEffect(() => {
    if (refreshSubscription) {
      API.get(`subscription?subscriptionId=${subscrcriptionId}&subsType=${subsType}&locId=${locId}`)
        .then((res) => {
          console.log("subscriptionGet", res.data.data)
          setConfig(res.data.data.config);
          const subscription = res?.data?.data?.subscriptionDetails
          if (subscription && Object.keys(subscription).length > 0) {
            let subscriptionDetails = {
              clientId: subscription.parking_client_id,
              clientName: subscription.client_name,
              userId: subscription.user_id,
              firstName: subscription.first_name,
              lastName: subscription.last_name,
              phoneNo: subscription.phone,
              locationId: subscription.parking_location_id,
              subLocationAccess: subscription.subLocationAccess,
              vehicleType: subscription.vehicle_type
            };
            // for user subscription edit
            if (subscription.plus_id) {
              subscriptionDetails.userSubscriptionId = subscription.plus_id
              subscriptionDetails.startDate = moment(subscription.start, "DD-MM-YYYY hh:mm a").toDate()
              subscriptionDetails.endDate = moment(subscription.end, "DD-MM-YYYY hh:mm a").toDate()
              subscriptionDetails.amount = subscription.amount
            } else if (subscription.pcu_id) { //for client's user subscription edit
              subscriptionDetails.pcu_id = subscription.pcu_id
            }
            const autoTagIDs = subscription.auto_tag_id?.split(",");
            let vehicleDetails = [];
            if (autoTagIDs) {
              const vehicleTypes = subscription.type?.split(",");
              const tagTypes = subscription.tag_type?.split(",");
              const vehicleNos = subscription.vehicle_no?.split(",");
              const activeStatus = subscription.auto_tag_active?.split(",");
              const vehicleTag = subscription.vehicle_tag?.split(",");
              for (let index = 0; index < autoTagIDs.length; index++) {
                vehicleDetails[index] = {
                  autoTagId: autoTagIDs[index],
                  vehicleType: vehicleTypes[index],
                  vehicleTag: vehicleTag[index],
                  vehicleTagType: tagTypes[index],
                  vehicleNo: vehicleNos[index],
                  active: parseInt(activeStatus[index]) === 1 ? true : false
                };
              }
            }
            setInitialValues({
              subscriptionDetails: subscriptionDetails,
              vehicleDetails: vehicleDetails
            })
          }

        })
        .catch((err) => {
          console.log("catch", err);
        });
    }
    setRefreshSubscription(false);
  }, [refreshSubscription]);

  console.log("config", config);
  return (
    <>
      <ContainerHeader title={`Edit Subscription ${locName ? '- ' + locName : ''}`} match={props.match} />
      {(config && Object.keys(initialValues).length > 0) && (
        <Formik
          onSubmit={updateSubscription}
          initialValues={initialValues}
          validationSchema={validations(initialValues)}
          enableReinitialize={true}
        >
          <SubscriptionSections config={config} />
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default EditSubscription;

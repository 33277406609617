import React, { useEffect, useState } from 'react'
import { Formik, connect } from 'formik'
import CardBox from 'components/CardBox'
import {Box,Grid,Select,DialogContent,MenuItem,IconButton,TextField, Button} from '@material-ui/core'
import MsgPopover from 'components/MsgPopover'
import FormField from 'components/FormField'
import { SwapHoriz } from '@material-ui/icons'
import ControllerDetails from './ControllerDetails'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import SnackBar from 'components/SnackBar'
import API from '../../../../../util/Api'
import PopUpDialog from 'components/PopUpDialog'
import * as Yup from "yup"
import _ from 'lodash'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import Refresh from '@material-ui/icons/Refresh';
import HistoryIcon from '@material-ui/icons/History'
import LinearProgress from "@material-ui/core/LinearProgress";

//import { useParams } from 'react-router'
const SignupSchema = (config) => {
    return Yup.lazy(values => {
        console.log("SignupSchema", config, values);
        return Yup.object().shape({
            name: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
            mac_address: Yup.string()
                .required('Required'),
            type: Yup.string()
                .required('Required'),
            location_id: Yup.object().nullable().when("type", {
                is: (type) => type === 'main' || type === 'rfid' || type === 'led' || type === 'loop',
                then:(schema) => schema.required("Required")
            }),
            charging_point: Yup.object().when("type", {
                is: (type) => type === 'evCharging',
                then: (schema) => schema.required("Required")
            })
        });
    })
}
const CommandComp = (props) => {
    console.log("CommandComp", props)
    const [command, setCommand] = useState('');
    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <Select fullWidth label={'select reset command'} name={'cmd'} style={{marginTop:'16px'}} onChange={e => {
                    console.log("selectCommand", e.target)
                    props.setCompForm({ [e.target.name]: e.target.value, c_id: props.clicked.id })
                    setCommand(e.target.value)
                }} >
                    {
                        props.commandList?.map(item => <MenuItem key={`key-${item.value}`} value={item.value} >{item.title}</MenuItem>)
                    }
                </Select>
            </Grid>
            {command === 'update_with_firmware' && <Grid item xs={5}>
                <Select fullWidth label={'select firmware version'} name={'version'} style={{marginTop:'16px'}} onChange={e => {
                    console.log("selectVersion", e.target)
                    props.setCompForm({ [e.target.name]: e.target.value, c_id: props.clicked.id, cmd: command })
                }} >
                    {
                        props.firmwareVersionList[`lite-${props.clicked.type.toLowerCase()}`] ? props.firmwareVersionList[`lite-${props.clicked.type.toLowerCase()}`].map((item, index) => <MenuItem key={`fv-${index}`} value={item} >{item}</MenuItem>) : <MenuItem value="" >No Firmware Available</MenuItem>
                    }
                </Select>
            </Grid>}
            {command === 'update_hw_version' && <Grid item xs={5}>
                <TextField label="Hardware Version" name="hardware_version" onChange={e => {
                    props.setCompForm({[e.target.name]: e.target.value, c_id: props.clicked.id, cmd: command  })
                }} />
            </Grid>}
        </Grid>
    )
}
const FirmwareVersion = (props) => {
    console.log("Fversion props", props)
    return (
        <Grid container>
            <Grid item xs={5}>
                <Select fullWidth label={'select firmware version'} name={'firmware_version'} onChange={e => {
                    console.log('firmwareChange()', e.target)
                    props.setFirmwareForm(e.target)
                }} >
                    {
                        props.firmwareVersionList[`lite-${props.clicked.type.toLowerCase()}`] ? props.firmwareVersionList[`lite-${props.clicked.type.toLowerCase()}`].map((item, index) => <MenuItem key={`fv-${index}`} value={item} >{item}</MenuItem>) : <MenuItem value="" >No Firmware Available</MenuItem>
                    }
                </Select>
            </Grid>
        </Grid>
    )
}

const ConfigField = (props) =>{
    let field = _.cloneDeep(props.field)
    const formik = props.formik;
    const locControllers = props.locControllers[0].options;

    if (field.dependency) {
        let fieldValue = formik.values[field.dependency] || null;
        
        if (fieldValue) {
            let selectedLocation = formik.values['parking_location_id'];
            if (field.key === 'controllers') {
                if (selectedLocation) {
                    const filteredLocController = locControllers.find(loc => loc.value == selectedLocation);
                    const fieldOptions = filteredLocController.controllers ? filteredLocController.controllers : [];
                    
                    field.options = [...fieldOptions];
                } else {
                    field.options = [];
                }
            }

            return (<div className={`col-md-${props.field.size} col-14`} key={`field-${props.field.key}`}>
                <FormField
                    field={{
                        ...field
                    }}
                />
            </div>);
        }
    }
    return(<div className={`col-md-${props.field.size} col-14`} key={`field-${props.field.key}`}>
        <FormField
            field={{
                ...field,
                ...{name:field.key}
            }}
        />
    </div>);
}
const EZField = connect(ConfigField)

const DeleteTxCmd = (props) =>{
    const locControllers = props.delTxCmdConfig?.fields.filter(f => f.key === 'parking_location_id') || [];
    const initialFormValues = { parking_location_id:'', controllers:[], tag:'', timestamp:'' };
    

    return(
        <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
                console.log("Form Submit", values);
                props.agreeClicked(null, values, 'deleteTxFromCtrlr');
            }}
        >
        {({ handleSubmit }) => (
            <Grid container direction='row'>
                <Grid fullWidth container style={{marginRight:10}} direction='row'>
                {props.delTxCmdConfig.fields?.map((field, fieldIndex) => (
                    <EZField
                    field={{
                        ...field,
                        ...{ name: `${field.key}` },
                            ...{ options: field?.options ? field?.options : [] },
                            ...{ disabled: false }
                        }}
                        key={field.key}
                        locControllers={locControllers}
                    />
                ))}
                </Grid>
                <Grid container justifyContent="flex-end" >
                <Grid  item xs={3} container justifyContent="center" style={{ margin: 5 }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Send
                    </Button>
                </Grid>
                </Grid>
            </Grid>
        )}
    </Formik>
    )
}
const ControllerV2 = (props) => {
    const [values, setValues] = useState(null)
    const [config, setConfig] = useState(null)
    const [resetF, setResetF] = useState(false)
    const [controllerList, setControllerList] = useState(null)
    const [controller, setController] = useState(null)
    const [snackBar, setSnackBar] = useState({ open: false, msg: '', type: 'error' });
    const [dialog, setDialog] = useState({ open: false, content: '', header: '', agreeBtnTxt: '', disAgreeBtnTxt: '', reset: null });
    const [commandList, setCommandList] = useState([])
    const [firmwareVersionList, setfirmwareVersionList] = useState([])
    const [dialogFormData, setDialogFormData] = useState({})
    const [dialogFormURL, setDialogFormURL] = useState('')
    const [delTxCmdConfig, setDelTxCmdConfig] = useState()
    const [loading, setIsLoading] = useState(false);
    const formatControllerList = (controllerList) => {
        let tBody = []
        for (let body of controllerList.body) {
            console.log("tBody", body)
            tBody.push(body.tableList)
        }
        let table = {
            ...controllerList,
            'tbody': tBody
        }
        setControllerList(table)
    }
    const saveController = (e, { setSubmitting }) => {
        console.log("saveController", e)
        setSubmitting(true)
        API.post("controllerV2", JSON.stringify(e))
            .then((res) => {
                console.log("controllerpost", res.data.data?.controller)
                if (controllerList) {
                    // let list = _.cloneDeep(controllerList)
                    // let controller = res.data.data.controller
                    // list['body'] = _.unionBy(controller,list.body,'id');
                    // formatControllerList(list)
                    formatControllerList(res.data.data.controllersList);
                }
                setSubmitting(false)
                setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
                resetController()
            })
            .catch((err) => {
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                }
                setSubmitting(false)
                console.log("catch", err);
            })

    }
    const resetController = () => {
        setController(null)
        setResetF(true)
    }
    useEffect(() => {

        getControllerV2List();
    }, [])

    const getControllerV2List = () => {
        setIsLoading(true);
        API.get("controllerV2").then((res) => {
            console.log("ControllerGet", res.data.data)
            let config = res.data.data.config
            let data = {}
            config.fields.forEach(field => {
                data[field.key] = field.value
            });
            setConfig(config)
            console.log('values', data);
            setValues(data)
            formatControllerList(res.data.data.controllersList);
            setCommandList(res.data.data.commands)
            setfirmwareVersionList(res.data.data.firmwareVersions)
            setDelTxCmdConfig(res.data.data.delCmdConfig);
            setIsLoading(false);
        }).catch((err) => {
            console.log("catch", err);
        })

    }
    const handleControllerEdit = (clicked) => {

        let clickedController = _.find(controllerList.body, { id: clicked.id }).fieldValues
        console.log("handleControllerEdit", clicked.id, clickedController, values)
        setController(clickedController)
        // scroll to top 
        document.body.style.height = 'auto'
        setTimeout(function () {
            document.body.style.height = '100%'
        }, 50)
    }
    const sendCommand = (clicked) => {
        console.log("sendCommand", clicked)
        setDialog({
            open: true,
            header: "Select command",
            content: <CommandComp clicked={clicked} commandList={commandList} firmwareVersionList={firmwareVersionList} setCompForm={setDialogFormData} />,
            agreeBtnTxt: 'Send',
            disAgreeBtnTxt: 'Cancel',
        })
        setDialogFormURL('mqttMsg')
    }
    const openDeleteTxPopUp = ()=>{
       
        setDialog({
            open:true,
            header:"Send Delete command for vehicle",
            content:<DeleteTxCmd  agreeClicked={agreeClicked} delTxCmdConfig={delTxCmdConfig} />
        })
        
     }
        
    const agreeClicked = (e,data={},formUrl=undefined) => {
        
        let formData = Object.keys(data).length>0?data:dialogFormData
        formUrl = (formUrl)?formUrl:dialogFormURL
        console.log("agreeClicked--",dialogFormURL,' -- ',formData)
        setDialog({ ...dialog, showProgress: true });
        if (Object.keys(formData).length > 0) {
            if(formUrl==='updateControllerStatus'){
                API.put(formUrl, JSON.stringify(formData))
                .then((res) => {
                    dialog?.callback && dialog.callback(formData.is_active == 1 ? true : false)
                    handleDialogClose()
                    if (res.data.msg) {
                        setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
                    }
                }).catch(err => {
                    handleDialogClose()    
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                })
            }else{
            API.post(formUrl, JSON.stringify(formData))
                .then((res) => {
                    handleDialogClose()
                    if (res.data.msg) {
                        setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
                    }
                    
                }).catch(err => {
                    handleDialogClose()
                    try {
                        setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                    } catch {
                        setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                    }
                })
            }
        }
    }

    const firmwareVersion = (clicked) => {
        setDialog({
            open: true,
            header: "Update Firmware Version",
            content: <FirmwareVersion clicked={clicked} firmwareVersionList={firmwareVersionList} setFirmwareForm={e => setDialogFormData({ [e.name]: e.value, controller_id: clicked.id, controller_type: clicked.type })} />,
            agreeBtnTxt: 'Update',
            disAgreeBtnTxt: 'Cancel'
        })
        setDialogFormURL('firmwareVersion')
    }
    const updateFirmware = (clicked) => {
        console.log("clicked-update-firmware", clicked);
        props.history.push({
            pathname: `${props.match.url}/update-firmware`,
            state: clicked,
        });
    };
    //    const onSetting = (clicked) => {
    //         console.log('clicked controller',clicked)
    //        window.open({
    //         pathname: `${props.match.url}/${clicked.id}`,
    //           state: clicked,

    //         });
    //         console.log(clicked);

    //   };

    const updateStatus = (id, checked, cb) => {
        let controller_v2 = _.find(controllerList.tbody, (controller) => controller.id === id);
        var text = checked ? `Are you sure you want to activate ${controller_v2.name}?` : `Are you sure you want to deactivate ${controller_v2.name}?`
        var header = checked ? 'Activate the Controller' : 'Deactivate the Controller'
        setDialogFormURL('updateControllerStatus');
        setDialogFormData({id:id, is_active:checked? 1: 0});
        setDialog({
            open: true,
            header: header,
            content: text,
            agreeBtnTxt: 'Confirm',
            disAgreeBtnTxt: 'Cancel',
            callback:cb
        })
    }

    const onSetting = (clicked) => {
        console.log('clicked controller', clicked);
        window.open(`${props.match.url}/${clicked.id}/${clicked.name}/${clicked.type}`, '_blank');
        console.log(clicked);

    };

    const handleDialogClose = () => {
        setDialog({
            open: false,
            content: '',
            header: '',
            agreeBtnTxt: '',
            disAgreeBtnTxt: '',
            reset: null,
            showProgress: false
        });
        setDialogFormData({})
        setDialogFormURL('')
    }
    return (
        <>
            {
                config && values &&
                <Formik
                    onSubmit={saveController}
                    onReset={resetController}
                    initialValues={values}
                    validationSchema={SignupSchema(config)}
                    resetInitalValue={true}

                >
                    <ControllerDetails
                        config={config}
                        controller={controller}
                        resetFormik={resetF}
                        setFalse={() => { console.log('setResetF'); setResetF(false) }}
                    />
                </Formik>
            }
            {loading && (
                <div>
                    <LinearProgress color="primary" />
                    <LinearProgress color="primary" />
                </div>
            )}
            {
                (controllerList) ?
                    <div className="row">
                        <CardBox styleName="col-12" headerOutside
                            heading={
                                <React.Fragment>
                                    {controllerList.title}
                                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                                        <Box display={{ xs: 'inline', md: 'none' }}><SwapHoriz color="primary" /></Box></MsgPopover>
                                    <Box style={{float:'right'}} >
                                        <IconButton aria-label="Send Delete cmd" onClick={openDeleteTxPopUp}
                                            title="Send delete transaction to NFC Lite controller">
                                            <AutorenewIcon />
                                        </IconButton>
                                        <IconButton aria-label="deployment-history" onClick={()=>{
                                                    window.open(`${props.match.url}/deployment-history`, '_blank');

                                        }}
                                            title="Controller Deployment History">
                                            <HistoryIcon />
                                        </IconButton>
                                        <IconButton aria-label="refresh table" onClick={() => {setControllerList(null);getControllerV2List()}}
                                            title="Refresh the Controller table">
                                            <Refresh />
                                        </IconButton>
                                    </Box>
                                </React.Fragment>
                            }>
                            <SimpleTable
                                type={'controller-v2'}
                                table={controllerList}
                                onEdit={handleControllerEdit}
                                sendCommand={sendCommand}
                                firmwareVersion={firmwareVersion}
                                updateFirmware={updateFirmware}
                                onSetting={onSetting}
                                updateStatus={updateStatus}
                            />
                        </CardBox>
                    </div> : <></>
            }
            <PopUpDialog
                fullWidth
                open={dialog.open}
                header={dialog.header}
                content={dialog.content}
                agreeBtnTxt={dialog.agreeBtnTxt}
                disAgreeBtnTxt={dialog.disAgreeBtnTxt}
                agreeClicked={agreeClicked}
                handleDialogClose={handleDialogClose}
                showProgress={dialog.showProgress}
            />
            {
                <SnackBar
                    open={snackBar.open}
                    handleClose={() => { setSnackBar({ open: false, msg: "" }) }}
                    message={snackBar.msg}
                    type={snackBar.type}
                />
            }
        </>
    )
}
export default ControllerV2
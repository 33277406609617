import axios from 'axios';

const axiosInstance =  axios.create({
  baseURL: `https://api-pd.valetez.com/pd/v2/`,//YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  }
});

// fixme: if not authorized lets go to the signin page. Might not be the best way
// fixme: check if this works correctly with redux
// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('interceptor request',config)
    // check for token
    if(!config.headers.Authorization){
        let token  = localStorage.getItem("token");
        if(token){
            token = JSON.parse(token)
            axiosInstance.defaults.headers.common['Authorization'] = token;
            config.headers.Authorization = token
        }else if('login' !== config.url){
            window.location.href = '/'
        }        
    }
    return config;

    }, function (error) {
    // Do something with request error
    console.log('interceptor request',error)
    return Promise.reject(error);
});

// Add a response interceptor
    axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('interceptor response',response)
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error    
    console.log('interceptor response',error.response)
    if(400 === error.response.status || 503 === error.response.status){  
        // user logged out by server
        localStorage.removeItem("token")
        const referrer = encodeURIComponent(window.location.pathname+window.location.search);
        window.location.href = `/signin?referrer=${referrer}`        
        return
    }else{
        return Promise.reject(error);
    }
    
});

export default axiosInstance

import React, { useState,useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader'
import TerminalBox from 'components/TerminalBox'
import CardBox from 'components/CardBox'
import {Box,Button,InputLabel,TextField, Typography,FormHelperText,LinearProgress} from '@material-ui/core'
import {DateTimePicker} from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles} from '@material-ui/core/styles'
import CircularProgress from 'components/Loader'
import API from '../../../../util/Api'
import _ from 'lodash'
import moment from "moment"

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const LedLogs = (props) =>{
    const classes = useStyles()
    const [loc,setLoc] = useState(null)
    const [deviceList,setDeviceList] = useState([])
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [device,selectedDevice] = useState(null)
    const [logs,setLogs] = useState(null)
    const [startTime,setStartTime] = useState('')
    const [endTime,setEndTime] =  useState('')
    const [fetchOlderlogs,setFetchOlderlogs] = useState(false)
    const [dateRange,setDateRange] = useState({
        startDateTime : null,
        endDateTime :  null
    })
    const [intervalRange,setIntervalRange] = useState({
        startDateTime : null
    })
    const [showProgress,setShowProgress] = useState(false)

    useEffect(()=>{
        API.get("location?led=true").then(response=>{
            setLoc(response.data.data)
        }).catch(error=>{
        })
    },[])
    const handleSubmit =(from)=>{

        if(device){
            
            let url = {}
            if(fetchOlderlogs){ // called when get older btn clicked
                url = { startTime: startTime, endTime: endTime }
                setFetchOlderlogs(false)
            }else if(dateRange.startDateTime && dateRange.endDateTime){ // if date range is selected
                let dateRangeStartTime = moment(dateRange.startDateTime).format("X")
                let dateRamgeEndTime = moment(dateRange.endDateTime).format("X")
                //setting starttime  to fetch older logs
                setStartTime(dateRangeStartTime)
                url = {startTime: dateRangeStartTime, endTime: dateRamgeEndTime }
            }else if(intervalRange.startDateTime){ // called on refresh or on evry interval
                url = {startTime: intervalRange.startDateTime }
            }

            console.log("handleSubmit2",url)
            const data = { type: "logs", filter:[device.device_id], ...url }
            API.post('findledlogs', JSON.stringify(data)).then(response=>{
                setShowProgress(false)
                console.log("findlogsResponse",response.data.data)
                //reset logs if submit clicked again (from!=='submit')
                if(from!=='submit' && logs && logs.length>0){
                    let serverLogs = response.data.data.data[device.device_id]
                   let newData = _.unionBy(serverLogs,logs,'token')
                   newData = _.orderBy(newData,['timeStamp'],['asc'])
                   setLogs(newData)
                   console.log("newData",newData,logs)
                }else{
                    setLogs(response.data.data.data[device.device_id])
                    let min_obj = _.minBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setStartTime(min_obj.timeStamp)
                    
                }
                if(response.data.data.data && Object.keys(response.data.data.data).length>0){
                    
                    let max_obj = _.maxBy(response.data.data.data[[device.device_id]], function(o) { return o.timeStamp; });
                    setIntervalRange({
                        startDateTime:max_obj.timeStamp
                    })
                }
                    
            }).catch(error=>{
                setShowProgress(false)
            })
        }else{
            console.log("please select led")
        }
        
    }
    useEffect(()=>{
        // if date range is selected, don't allow auto update
        if(!(dateRange.startDateTime && dateRange.endDateTime) && logs){
            const timer = setInterval(() => {
                if(device)
                    handleSubmit()
            }, 1000*30);
            return () => clearInterval(timer);
        }
        
    })
    useEffect(()=>{
        if(fetchOlderlogs==true)
            handleSubmit()
    },[fetchOlderlogs])
    const handleReset = () =>{
        console.log("handleReset")
        setSelectedLoc(null)
        setDateRange({
            startDateTime : null,
            endDateTime :  null
        })
        selectedDevice(null)
        setLogs(null)
        setIntervalRange({
            startDateTime : null
        })
        setShowProgress(false)
    }
    console.log("Led-States",showProgress)
    return(
        <>
            <ContainerHeader title={'LED Logs'} match={props.match}/>
            {
                loc && loc.length>0 &&
                <CardBox styleName="">
                    <div>
                        {/* 1st row */}
                        <div className={`row`} >
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {selectedLoc?selectedLoc:null}
                                    id = "location"
                                    name = "location"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{
                                        if(value){
                                            setSelectedLoc(value)
                                            let location = _.find(loc,{id:value.id})
                                            setDeviceList(location?location.leds:[])
                                            if(device){
                                                selectedDevice(null)
                                            }
                                        }else{
                                            setSelectedLoc(null)
                                            selectedDevice(null)
                                            setDeviceList([])
                                        }
                                        setLogs(null)
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                    }}
                                    options = {loc}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                            <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                            <TextField name="location" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {device}
                                    id = "deviceId"
                                    name = "deviceId"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>
                                        {
                                            if(value){
                                                selectedDevice(value)
                                            }else{
                                                selectedDevice(null)
                                            }
                                            setLogs(null)
                                            setIntervalRange({
                                                startDateTime : null
                                            })
                                        }
                                    }
                                    options = {(deviceList.length>0?deviceList:[])}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                                <InputLabel shrink={true} htmlFor="location" className={classes.label}>Led Name</InputLabel>
                                                <TextField name="deviceId" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'Start Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.startDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,startDateTime:date['_d']})
                                        }}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                        disableFuture
                                        minDate={new Date(moment(). subtract(15, 'days'))}
                                    />
                                </div>
                                <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'End Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        disableFuture
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.endDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,endDateTime:date['_d']})
                                        }}
                                        minDate={dateRange.startDateTime?dateRange.startDateTime:new Date()}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                                </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12 col-12">
                                <Box mt="20px">
                                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                        handleSubmit('submit')
                                        setShowProgress(true)
                                        setLogs(null)
                                    }}> Submit </Button></Box>
                                    <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={handleReset}> Reset </Button></Box>
                                </Box>
                            </div> 
                        </div>
                    </div>
                </CardBox>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {
                loc === null &&
                    <CircularProgress />
            }
            {
              logs && logs.length>0 &&
                <TerminalBox
                    logs={logs}
                    type={'Led'}
                    name= {(selectedLoc && device)?selectedLoc.name+' - '+device.name+" ("+device.device_id+")":''}
                    handleRefresh={!(dateRange.startDateTime && dateRange.endDateTime)?handleSubmit:undefined} // if date range is selected then hide refresh
                    getOlderLogs = {()=>{
                        setEndTime(startTime)
                        setStartTime(startTime-(30*60))
                        setFetchOlderlogs(true)
                    }}
                    allowScroll={!fetchOlderlogs}
                />
            }
            {
                logs && logs.length==0 &&
                 <Typography style={{padding:15}} >{`${(selectedLoc &&device)?'No Led logs for '+selectedLoc.name+' - '+device.name:''}`}</Typography>
            }
        </>
    )
}
export default LedLogs
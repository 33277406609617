import CardBox from "components/CardBox"
import ContainerHeader from "components/ContainerHeader"
import ReportFilter from "components/ReportTable/ReportFilter"
import moment from "moment"
import { useEffect, useState } from "react"
import API from "util/Api";
import OverviewDetails from "./OveriewDetails"
import _ from "lodash"
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => (
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight: '1em'
        },
        text: {
            color: theme.palette.secondary.main
        },
        label: {
            color: theme.palette.primary.main,
        },
        button: {
            backgroundColor: theme.palette.secondary.light,
            color: 'white'
        },
        underline: {
            display: 'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop: 6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon: {
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15: { marginBottom: "15px" },
        md20: { marginBottom: "20px" },
        md30: { marginBottom: "30px" },
    }
))
const ParkingOverView = (props)=>{
    
    const [locations,setLocations] = useState([])
    const [data,setData] = useState(undefined)
    const [utilizationSata,setUtilizationSata] = useState(undefined)
    const [lengthOfStay,setLengthOfStay] = useState(undefined)
    const reportFlags = _.find(props?.reportFlags, { name: 'parking-overview' })?.flags
    reportFlags.showDate = false;
    const [loading,setLoading] = useState(false)
    const [input, setInput] = useState({})
    useEffect(()=>{
        API.get("location", {params:{ loc_type: "parking" }}).then((res) => {
            const parkingLoc = res.data.data;
            setLocations(res.data.data);
            setInput({location: parkingLoc && parkingLoc.length > 0 ? (reportFlags.multiLocation ? [parkingLoc[0].id] : parkingLoc[0].id) : 0});
        })
    },[])

    const classes = useStyles();
    const getParkingOverview = (params)=>{
        params.type = 'parking-overview';
        setLoading(true)
        Object.keys(params).map(function (item, i) {
          if (item === "from" || item === "to")
            params[item] = new Date(params[item]).toISOString();
        });
        // Get today's date
        const today = new Date();
        const from = new Date(today);
        from.setHours(0, 0, 0, 0);  // Set hours to 12 AM today
        const to = new Date(today);
        to.setHours(24, 0, 0, 0);// Set to 11:59 PM
        // Convert to IST by adjusting the time zone offset (+5:30 for IST)
        const ISTOffset = 5.5 * 60; // IST is UTC +5:30 hours
        const localFrom = new Date(from.getTime() + (ISTOffset * 60000));
        const localTo = new Date(to.getTime() + (ISTOffset * 60000));
        // Format the result in a string format
        const formatDate = (date) => {
        return date.toISOString().replace("T", " ").split(".")[0];  // Format to 'yyyy-mm-dd hh:mm:ss'
        };
        params.from = formatDate(localFrom)
        params.to = formatDate(localTo)
        console.log("getParkingOverview-",params)
        setData(null)
        API.get("parking_overview?data=" + JSON.stringify(params)).then((res) => {
            setData(res.data.data)
            setLengthOfStay(res.data.data.length_of_stay ?? {data : [],legends:[]})
            setUtilizationSata(res.data.data.utilizationData ?? {data:''})
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
        })
    }
    useEffect(()=>{
        if(Object.keys(input).length > 0 && locations.length > 1){
            getParkingOverview(input);
        }
    },[input]);

    const handleLocationChange = (e, value) => {
        if (value) {
            if (!reportFlags.multiLocation)
                setInput({ ...input, 'location': value.id, "vehicle": _.cloneDeep(value.vehicle_types) })
            else {
                let locations = value.map(a => a.id)
                console.log('locations', locations)
                setInput({ ...input, 'location': value.map(a => a.id) })
            }
        }
    }

    const reportFilterComponent = (
        <div className={"col-sm-6 col-12 " + (reportFlags.multiLocation ? "col-lg-3" : "col-lg-4")}>
            <FormControl className="w-100">
                <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                <Autocomplete
                    size={reportFlags.multiLocation ? "small" : ""}
                    value={reportFlags.multiLocation
                        ? (Array.isArray(input.location)
                            ? _.filter(locations, ((o) => {
                                return _.includes(input.location, o.id);
                            }))    // find the locations which have id in input.location array
                            : [])
                        : (input.location ? _.find(locations, { 'id': input.location }) : null)
                    }
                    multiple={reportFlags.multiLocation}
                    onChange={handleLocationChange}
                    id="location"
                    name="location"
                    style={{ marginTop: 0 }}
                    options={locations}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" size="small" color="primary" key={value}
                                label={option.name}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField id="location-input" {...params} margin="normal" />
                    )}
                />
            </FormControl>
        </div>
    )

    return(
        <div>
            <ContainerHeader title={"Overview"} match={props.match} additionalContent={locations.length > 1 ? reportFilterComponent : null} />
            {
                reportFlags &&
                <div className="row">
                    {/* <CardBox styleName="col-12">
                        <ReportFilter
                            locations={locations}
                            reportType={['overview']}
                            requestStatus={{ msg: "", success: true }}
                            getReportData={getParkingOverview}
                            flags={reportFlags}
                            vendors={[]}
                            minFromDate={moment(Date.now()).subtract(39, "days")} 
                            maxToDate={moment(Date.now()).add(1, "days")}
                        />
                    </CardBox> */}
                    {
                        loading &&
                        <div className="w-100">
                            <LinearProgress color="primary" /><LinearProgress color="primary" />
                        </div>
                    }
                    
                    {
                        data && utilizationSata &&
                            <OverviewDetails data={data} utilizationSata={utilizationSata} lengthOfStay={lengthOfStay} ></OverviewDetails>
                    }
                </div>
            }
            
        </div>
    )
}
const mapStateToProps = function (state) {
  try {
    return {
      reportFlags: _.concat(
        state.auth.authUser.reports,
        state.auth.authUser.subsc,
        state.auth.authUser.ezcharge,
        state.auth.authUser.valet,
        state.auth.authUser.access
      ),
      dashboardActions: state.auth.authUser.dashboardactions,
    };
  } catch (err) { }
};
export default connect(mapStateToProps) (ParkingOverView)
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import motorCycleImage from '../../assets/images/motorCycle.png'; 
import carETA from '../../assets/images/carETA.png'; 
import {
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip as ReToolTip
} from "recharts";
import MuiTooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    ResponsiveContainer,
    ComposedChart,
    BarChart,
    Bar,
    Label,
    XAxis,
    YAxis,
    LabelList,
    Legend as ReLegend
  } from "recharts";

  const yellowPalette = [
    '#bdade4', // Light Yellow
    '#9ee9d1', // Soft Yellow
    '#f9c880', // Golden Yellow
    '#f399a3', // Dark Yellow
    '#ebdc78', // Amber Yellow
    '#FFCA28', // Mustard Yellow
    '#FFD54F', // Light Gold
    '#FF6F00', // Dark Gold
  ];
export const EZDoughnut = (props)=>{
  
    const image = new Image();
  
    image.src = props.type == 'bike' ? motorCycleImage : carETA;
    
    const plugin = {
      id: 'customCanvasBackgroundImage',
      beforeDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx;
          const {top, left, width, height} = chart.chartArea;
          const imageWidth = 55; // Set the width you want
          const imageHeight = 55; 
          const x = left + width / 2 - imageWidth / 2;
          const y = top + height / 2 - imageHeight / 2;
          ctx.drawImage(image, x, y,imageWidth,imageHeight);
        } else {
          image.onload = () => chart.draw();
        }
      }
    }; 
    const options =  {
        cutout: '75%',
    }
    // Chart configuration
  const config = {
    type: 'doughnut',
    data: props.data,
    plugins: [plugin],
  };
    if(props.legend){
        ChartJS.register(ArcElement, Tooltip,Legend);

    }else{
        ChartJS.register(ArcElement, Tooltip);
    }
    return(<Doughnut  data={props.data} options={options}  plugins={config.plugins} style={props?.style} />)
}

export  const EZLineChart = (props) => {
  // console.log("EZLineChart_____", props.data);

  const MaxDataValue = Math.ceil(
    props.data.data.reduce((max, item) => {
      const maxPickup = parseInt(item.total_pickup, 10);
      const maxReturn = parseInt(item.total_return, 10);
      return Math.max(max, maxPickup, maxReturn);
    }, 0) * 1.5 / 10
  ) * 10;

  return (
    <ResponsiveContainer height={props.height} width={props.width}>
      <LineChart
      height={50}
        width={'50%'}
        data={props.data.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <filter id="shadow" height="200%">
            <feDropShadow
              dx="0"
              dy="5"
              stdDeviation="8"
              floodColor={"#82ca9d"}
            />
          </filter>
        </defs>
        <CartesianGrid
          strokeDasharray="6 1 2"
          horizontal={false}
          strokeOpacity={0.3}
        />
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis domain={[0, MaxDataValue]} padding={{ top: 10, bottom: 10 }} axisLine={false} tickLine={false} />
        <ReToolTip
          content={({ active, payload }) => {
            return active ? (
              <div sx={{ color: "common.white" }}>
                {payload?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={index !== payload.length - 1 ? "mb-1" : ""}
                    >
                      <div
                        style={{
                          color: row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {row.payload.name}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}
                      >
                        {row.value ? row.value : 0 } {row.dataKey.split('_').join(' ').toUpperCase()}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: "rgba(0,0,0,0.9)",
            borderRadius: 4,
            padding: "5px 8px",
            fontWeight: 500,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        />
        { props.data.lines?.map((line) => (
          <Line
            type="linear"
            strokeWidth={2}
            dataKey={line.dataKey}
            stroke={line.color}
            filter="url(#shadow)"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};


export const PaymentModesPieChart = (props)=>{
  const getPercentage = (value, type) => {
    let percentage = 0;
    if(type === 'trans'){
      percentage = Number(props.data.totalTrns) > 0 ? ((Number(value)/Number(props.data.totalTrns)) * 100).toFixed(2) : 0;
    } else{
      percentage = Number(props.data.totalAmount) > 0 ? ((Number(value)/Number(props.data.totalAmount)) * 100).toFixed(2) : 0;
    }
    return percentage;
  }
  // console.log("PaymentModesPieChart____", props);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <MuiTooltip
          arrow
          placement="top"
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <Box sx={{ display: 'inline-block' }}>
            {/* The component where the tooltip will appear */}
            <Typography sx={{ cursor: 'pointer', color: '#007BFF' }}>
            <Box sx={{ padding: 8, borderRadius: 5, bgcolor: 'common.black', letterSpacing: 1, textTransform: "uppercase", boxShadow: 2 }}>
              <Typography variant="subtitle2" style={{ color: payload[0]?.payload.color }}>
                {payload[0]?.payload.label}
              </Typography>
              <Typography variant="body2" style={{ color: 'white', fontSize: 14 }}>
                {'Transactions:'} <br/> {`${payload[0]?.value.toLocaleString()} (${getPercentage(payload[0]?.value, 'trans').toLocaleString()}%)`}
              </Typography>
              <Typography variant="body2" style={{ color:  'white', fontSize: 14 }}>
                {'Total Amount:'} <br/> {`${payload[0]?.payload.amount.toLocaleString()} (${getPercentage(payload[0]?.payload.amount, 'amount').toLocaleString()}%)`}
              </Typography>
            </Box>
            </Typography>
          </Box>
        </MuiTooltip>
      );
    }
    return null;
  };
  return(
    <PieChart height={props.height ?? 400} width={props.width ?? 400} >
      <Pie
        data={props.data.data}
        cx="50%"
        cy="50%"
        outerRadius={120}
        dataKey="data"
        >
        {props.data.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <ReToolTip content={<CustomTooltip/>}/>
    </PieChart>
  )
}


export const EZDoughnutSolid = (props)=>{

  console.log(props,"props")
  const CustomTooltip = ({ active, payload }) => {
    console.log(payload)
    if (active && payload && payload.length) {
      return (
        <MuiTooltip
        arrow
        placement="top"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
      >
      <Box sx={{ display: 'inline-block' }}>
        {/* The component where the tooltip will appear */}
        <Typography sx={{ cursor: 'pointer', color: '#007BFF' }}>
        <Box sx={{ padding: 1, borderRadius: 1, bgcolor: '#fff', boxShadow: 2 }}>
          <Typography variant="subtitle2" sx={{ color: '#555', fontWeight: 600 }}>
            {payload[0].payload.label}
          </Typography>
          <Typography variant="body2" sx={{ color: '#007BFF', fontWeight: 500 }}>
            {`Value: ₹${payload[0].value.toLocaleString()}`}
          </Typography>
        </Box>
        </Typography>
      </Box>
    </MuiTooltip>
      );
    }
    return null;
  };
  return(
    <PieChart width={400} height={250}>
    <Pie
      data={props.data}
      cx="50%"
      cy="50%"
      outerRadius={120}
      dataKey="data"
    >
      {props.data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={yellowPalette[index]} />
      ))}
    </Pie>
    <ReToolTip content={<CustomTooltip/>}/>
  </PieChart>
  )
}

export const BarChartt = (props)=>{
    const transformedData = [
        {
            name: 'Vehicles',
            bike: props.data.datasets[0].data[0],  
            car: props.data.datasets[0].data[1],  
            bikeColor: props.data.datasets[0].backgroundColor[0],
            carColor:  props.data.datasets[0].backgroundColor[1] 
        }
    ];
    return (
        <ResponsiveContainer>
            <BarChart 
                style={props?.style}
                data={transformedData}
                layout="vertical" 
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <XAxis type="number" hide />
                <YAxis type="category" hide />
                 {/* Bar for "bike" with labels */}
                 <Bar dataKey="bike" stackId="a" fill={transformedData[0].bikeColor}>
                    <LabelList dataKey="bike" position="inside" fill="white" />
                </Bar>

                {/* Bar for "car" with labels */}
                <Bar dataKey="car" stackId="a" fill={transformedData[0].carColor}>
                    <LabelList dataKey="car" position="inside" fill="white" />
                </Bar>
            </BarChart>
     </ResponsiveContainer>
    )
}


export  const ChartSales = (props) => {
  const maxData = 100;
  return (
    <ResponsiveContainer height={props.height}>
      <LineChart
        // width={480}
        
        data={props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <filter id="shadow" height="200%">
            <feDropShadow
              dx="0"
              dy="5"
              stdDeviation="8"
              floodColor={"#82ca9d"}
            />
          </filter>
        </defs>
        <CartesianGrid
          strokeDasharray="6 1 2"
          horizontal={false}
          strokeOpacity={0.3}
        />
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis dataKey={"count"} domain={[0, maxData]} padding={{ top: 10, bottom: 10 }} axisLine={false} tickLine={false} />
        <ReToolTip
          content={({ active, payload }) => {
            return active ? (
              <div sx={{ color: "common.white" }}>
                {payload?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={index !== payload.length - 1 ? "mb-1" : ""}
                    >
                      <div
                        style={{
                          color: row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {row.payload.name}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}
                      >
                        {row.value ? row.value + '%' : 0 + '%'} {props.data[0].type.toUpperCase()+'S'}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: "rgba(0,0,0,0.9)",
            borderRadius: 4,
            padding: "5px 8px",
            fontWeight: 500,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        />
        <Line
          type="linear"
          strokeWidth={2}
          dataKey="count"
          stroke="#82ca9d"
          filter="url(#shadow)"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};


// export const StackedBarChart = (props)=>{
//   console.log(props,'props')
//   return(
//     <ResponsiveContainer width="100%" height={200} id="tagTransaction">
//     <BarChart data={props.data} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
//         <XAxis dataKey="name"/>
//         <YAxis/>
//         <CartesianGrid strokeDasharray="3 3"/>
//         {/* <Tooltip  labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/> */}
//         <ReLegend/>
//         <defs>
//             <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
//                 <stop offset="5%" stopColor="red" stopOpacity={1}/>
//                 <stop offset="95%" stopColor="black" stopOpacity={1}/>
//             </linearGradient>
//         </defs>
//         <defs>
//             <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
//                 <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1}/>
//                 <stop offset="95%" stopColor="#09BCA7" stopOpacity={1}/>
//             </linearGradient>
//         </defs>
//         <Bar maxBarSize={30}  dataKey='Available' stackId="a" />
//         {/* <Bar maxBarSize={30}  dataKey='Occupied' stackId="a" /> */}
//         {/* {
//             props.data.map( (tags,index) =>{
//                 return (
//                     <Bar maxBarSize={30}  dataKey={tags} stackId="a" />
//                 )
//             })
//         }                                       */}
//     </BarChart>  
// </ResponsiveContainer>
//   )
// }

export const StackedBarChart = ({ data, vehicleType, style }) => {
  console.log("Vehicle Type:", vehicleType);  // Log to ensure it's being passed
  console.log("Data:", data);  // Log to verify data is correct
  

const CustomXAxisTick = ({ x, y, payload, data }) => {
  let currentFilter = data.filter(location => location.clientname == payload.value)[0]
  console.log(currentFilter,'aaaa',data)
  const bikeAvailableExists = currentFilter && currentFilter.hasOwnProperty('bikeAvailable') && currentFilter.hasOwnProperty('bikeOccupied');
  const carAvailableExists = currentFilter && currentFilter.hasOwnProperty('carAvailable' )&& currentFilter.hasOwnProperty('carOccupied')
  return (
    <g transform={`translate(${x},${y})`}>
      {
        bikeAvailableExists &&
        <text x={-20} y={20} dy={5} textAnchor="middle" fill="#666">
          Bike
        </text>
      }
       {
        carAvailableExists &&
        <text x={20} y={20} dy={5} textAnchor="middle" fill="#666">
          Car
        </text>
      }
      <text x={0} y={0} dy={56} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

  return (
    <ResponsiveContainer width="100%" height={400}>
    <ComposedChart  data={data} margin={{ top: 20, right: 20, left: 0, bottom: 100 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
          dataKey="clientname"
          tickSize={10}
          tick={({ x, y, payload }) =>  <CustomXAxisTick x={x} y={y} payload={payload} data={data}/>}
        />      
      <YAxis />
      <ReToolTip />
      <ReLegend wrapperStyle={{marginTop:'-40px', position:'initial'}} />
      {/* Bike Data */}
      {/* <Bar dataKey="bikeTotal" fill="#8884d8" stackId='a' name="Bike Total" /> */}
      <Bar dataKey="bikeAvailable" fill="#82ca9d" stackId='a'  barSize={30}  name="Bike Available" />
      <Bar dataKey="bikeOccupied" fill="#ffc658" stackId='a'   barSize={30} name="Bike Occupied" />

      {/* Car Data */}
      {/* <Bar dataKey="carTotal" fill="#ff7300" stackId='b'  name="Car Total" /> */}
      <Bar dataKey="carAvailable" fill="#00c49f"  stackId='b'  barSize={30} name="Car Available" />
      <Bar dataKey="carOccupied" fill="#ffbb28"  stackId='b'  barSize={30} name="Car Occupied" />
    </ComposedChart>
  </ResponsiveContainer>
  );
};


export const HorizontalBarChart = (props)=>{
  console.log(props.data,'HorizontalBarChart')
  return (
    <ResponsiveContainer width={"100%"} height={500}>
    <BarChart 
      data={props.data}
      layout="vertical" 
      margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
    >
      <ReLegend verticalAlign="bottom" height={36} />
     <XAxis type="number" />
     <ReToolTip />
    <YAxis reversed dataKey="name" type="category" />
    <Bar barSize={20} dataKey="count" fill={props.color} />
    </BarChart>
  </ResponsiveContainer>
  )
}
import React, { useEffect, useState } from "react";
import API from "util/Api";
import { connect, Formik, useFormikContext } from "formik";
import SnackBar from "components/SnackBar";
import { Form } from "formik";
import { Button, Box, Grid, TextField, FormControl } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";
import SimpleTable from "components/ActionsTable/SimpleTable";

const EZField = connect((props) =>{
    let field = _.cloneDeep(props.field)
    const values = props.formik.values;

    if (field.dependency) {
        let fieldValue = values[field.dependency] || null;

        if(fieldValue && fieldValue.value === field.dependencyValue) {
            return(<div className="col-md-3 col-12"  key={`field-${props.field.key}`}>
                <FormField
                    field={{
                        ...field,
                        ...{name: field.key},
                        ...{options: field.options}
                    }}
                />
            </div>);
        } else {
            if(values[field.key] !== null){
                props.formik.setFieldValue(field.key, null);
            }
            return null;
        }
        
    }

    return(<div className="col-md-3 col-12" key={`field-${props.field.key}`}>
        <FormField
            field={{
                ...(field.key === 'tagType' && {disableClearable: true}),
                ...field,
                ...{name:field.key}                
            }}
        />
    </div>);
});

const FilterForm = (props) => {
    console.log('FilterForm-props', props);
    const { isSubmitting } = useFormikContext();

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {props.fields?.map((field) => (
                        <EZField 
                            field={{
                                ...field
                            }}
                        />
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
}

const SearchTag = (props) => {
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [tags, setTags] = useState();
    const [tagType, setTagType] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const validations = Yup.object().shape({
        // location: Yup.object().required("Required"),
    });

    const getFieldValues = (fields) => {
        const values = fields.reduce((obj, item) => {
            return { ...obj, [item.key]: item.value };
        }, {});
        return values;
    }

    useEffect(() => {
        API.get("tagSearch")
            .then((res) => {
                const values = getFieldValues(res.data.data.fields);
                setInitialValues(values);
                setConfig(res.data.data);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    const handleSubmit = (data, form) => {
        setIsSubmitting(true);
        setTags(undefined);
        API.post("tagSearch", JSON.stringify(data))
            .then((res) => {
                setTagType(data.tagType.value);
                setTags(res.data.data);
                setIsSubmitting(false);
                form.setSubmitting(false);
            })
            .catch((err) => {
                console.log("error", err);
                setIsSubmitting(false);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    return (
        config && initialValues &&
        <>
            <ContainerHeader title={config.title} match={props.match} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validations}
            >
                <FilterForm fields={config.fields} />
            </Formik>
            {isSubmitting &&
                <div >
                  <LinearProgress color="primary" />
                  <LinearProgress color="primary" />
                </div>
            }
            {tags && !isSubmitting &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <SimpleTable
                            type={tagType}
                            tableHeading={tags.title}
                            table={tags}
                        />
                    </CardBox>
                </div>
            }
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )
}
export default SearchTag;
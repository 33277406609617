/*
 * File: ConfigPage.js
 * Project: parkezdashboard
 * File Created: Thursday, 2nd July 2020 10:54:44 am
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Tuesday, 22nd September 2020 1:27:14 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog'
import ContainerHeader from 'components/ContainerHeader'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles,useTheme} from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import _ from 'lodash'
import API from 'util/Api';
import { Tooltip, Box, AccordionActions, Button, MenuItem, FormControl, InputLabel, IconButton, Divider } from '@material-ui/core'
import { Formik, Form, Field,connect, FieldArray,getIn } from 'formik'

import PlusOneIcon from '@material-ui/icons/PlusOne';
import FormField from 'components/FormField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { encode, decode } from 'util/secure';
import queryString from 'query-string';

const useStyles = makeStyles( (theme) =>(
    {
        section:{
            flexBasis: '100%',
            flexShrink: 0,
            flexWrap:'wrap',
            borderBottomWidth: 1,
            borderBottomColor:'rgba(0, 0, 0, 0.2)',
            borderBottomStyle:"solid",
            paddingBottom:15,
            paddingTop:15,
            '&:nth-last-child(1)':{
                borderBottomWidth: 0,
            }
        },
        field:{
            paddingTop:10,
            paddingBottom:10,
            display:'grid'
        },
        panelHeading : {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.primary.main,
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        panelSubheading : {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
            textAlign:'right'
        },
        expanded : {
            backgroundColor:'#efefef'
        },
        panelRoot : {
            flexWrap:'wrap'
        },
        panelAction : {
            flex:1,
            justifyContent:'flex-end'
        },
        addButton:{
            margin:'10px 0'
        }
    }
))

/**
 * Component to handle each field within a form
 * 
 * @param {*} props 
 */  
const ConfigField = (props) =>{
    console.log('ConfigField props - ', props)
    const classes = useStyles()    
    let field = props.field
    let prefix= props.name+(!isNaN(props.index) ? `[${props.index}]` :'')+
                (props.parent ? `.${props.parent.key}[${props.parent.index}]` : '')+ '.'
    let name  = prefix + field.key

    // check for dependency
    let dependencyValue = true
    let dependencyKey = field.dependency ? prefix+field.dependency : null
    if(dependencyKey){        
        dependencyValue =  getIn(props.formik.values,dependencyKey)
        if(!dependencyValue){
            // reset the value of this field if dependency is false
            let resetValue = (field.type === 'checkbox') ? false : ''
            let currentValue  = getIn(props.formik.values,name)
            if(currentValue !== resetValue) // check so that we donot enter a infinite loop
                props.formik.setFieldValue(name,resetValue)
        }
    }    
    if(props.add && field.disabled)
        field.disabled = false;
    // fixme: what on earth is this ??
     if((field.key==="allow_multiple" || field.key==="pass_validity") && field.dependency==="0") {
         return(null);
     }

     return(
        <>
        { dependencyValue &&
        <div className={classes.field + " col-sm-4 "+(field.size==='lg' ? 'col-lg-4' : field.size==="xl-1" ? 'col-lg-3 col-xl-1' : 'col-lg-3 col-xl-2')}>
            <FormField field={{...field,...{name:name}}} />
        </div> 
        }
        </>
    )
}

const getEmptyField = (field) =>{
    console.log('add field',field)
    let value = {}
    if(field.type === 'group'){
        field.fields.map(o =>{
            value[o.key] = o.multiSelect ? [] : ''
        })
    }else if(field.key === 'days'){
        value = field.options;
    }else{
        value = field.multiSelect ? [] : ''
    }
    return value
}

const getEmptySection = (section) =>{
    console.log('add section',section)
    let value = {}
    section.fields.map(field => {
        if(field.multi)
            value[field.key] = [getEmptyField(field)]
        else
            value[field.key] = getEmptyField(field)
        
    })        
    return value
}

const EZField = connect(ConfigField)

/**
 * Component for each section in a config form
 * 
 * @param {*} props 
 */
const ConfigSection  =(props)=> {

    const classes = useStyles();
    const key = props.section.key
    const values = isNaN(props.index) ? props.formik.values[key] : props.formik.values[key][props.index]
    console.log("ConfigSection",props,values,key)

    const name = props.name+(!isNaN(props.index) ? `[${props.index}]` :'')
    console.log('name',name)    
    
    const renderField = (key) => {
        let field = _.find(props.section.fields,{key:key})
        let fieldValue = values[key]

        // console.log('renderfield ',key,field)
         if(field.type==='group'){
            return(
                <div className="col-12" key={`field-${field.key}`}>
                    <Typography variant="subtitle2" color="primary">{field.title}</Typography>
                    <FieldArray
                        name={name+'.'+field.key}
                        render={arrayHelpers => (
                            <>
                            {fieldValue.map((v,i)=>(
                                <div className="row" key={`filed-wrapper-${i}`}>
                                {field.fields.map((o,fieldIndex)=>(    
                                    o.type === 'hidden' ?
                                    <input 
                                        key={`field-[${fieldIndex}]-${o.key}`} 
                                        type="hidden" name={props.name} 
                                        value={field.value} 
                                    /> :
                                    <EZField
                                        key={`field-[${fieldIndex}]-${o.key}`}
                                        name={props.name}
                                        index={props.index}
                                        field={{...o}}
                                        parent={{key:field.key,index:i}}
                                    />                  
                                ))}
                                {field.multi &&
                                    <div style={{alignSelf:'center'}}>
                                    <IconButton aria-label="remove" onClick={(e) => {console.log('remove index',i) ; arrayHelpers.remove(i)}} disabled={fieldValue[i]?.removeDisabled}>
                                        <RemoveIcon color={fieldValue[i]?.removeDisabled ? "disabled" : "error"}/>
                                    </IconButton>                                    
                                    {i === fieldValue.length-1 &&
                                        <IconButton aria-label="add" onClick={(e) => arrayHelpers.push(getEmptyField(field))}>
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    }
                                    </div>                                    
                                }                                
                                </div>
                            ))}
                            {fieldValue.length === 0 &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.addButton}
                                    startIcon={<PlusOneIcon />}
                                    onClick={(e) => arrayHelpers.push(getEmptyField(field))}
                                >
                                    {`Add ${field.title}`}
                                </Button>                                
                            } </>                               
                        )}
                    />
                </div>
            )
        }else{
            return(field.type === 'hidden' 
                ? <input key={`field-${field.key}`} type="hidden" name={field.name} value={field.value} /> 
                : <EZField key={`field-${field.key}`} name={props.name} index={props.index} field={field}/> 
            )
        }
    }

    return(
        
       <div mt={5} className={classes.section} key={props.index}>
            {/* <Divider/> */}
            <div className="row" >
                <div className="col-12">
                    <Typography variant="subtitle1" color="primary">{props.section.title}
                    {props.section.multi && props.section.key=="subratemodel" &&
                        <>
                        <IconButton aria-label="remove" onClick={(e) => {console.log('remove index',props.index);props.arrayHelpers.remove(props.index)}}>
                            <RemoveIcon color="error" />
                        </IconButton>
                        {props.index === props.formik.values[key].length-1 &&
                            <IconButton aria-label="add" onClick={(e) => {console.log('add section');props.arrayHelpers.push(getEmptySection(props.section))}}>
                                <AddIcon color="primary" />
                            </IconButton>
                        }
                        </>
                    }                    
                    </Typography>
                </div>
            </div>
            <div className="row">
                {Array.isArray(values) 
                ? values.map(v => (
                    Object.keys(v).map((key)=> (
                        renderField(key)                                                                          
                    ))
                ))
                : (
                    Object.keys(values).map((key)=> (
                        renderField(key)                                                                          
                    ))
                )
                }
                
            </div>
        </div>
        
    )
}

const Section = connect(ConfigSection)


/**
 * component for one config form .Has separate save button and api
 * @param {*} props 
 */
const Config = (props) => {
    console.log("Config",props)
    
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const [errorMessage, setErrorMessage] = useState('')
    const [isShowing, setisShowing] = useState(false)                
    const [loading, setisLoading] = useState(false)

    const saveConfig = (e,{setSubmitting}) => {
        console.log("save config",e)        
        setSubmitting(true)
        setisLoading(true)
        
        API.post(props.config.url, {...e,...{location_id:props.locId}})
            .then((res)=> {
                console.log("api response",res)
                setErrorMessage('Configuration saved')
                setisShowing(true)
                console.log("post config", res.data)
                props.onSave(e,props.config.key,res.data)
                setSubmitting(false)
                setisLoading(false)

            })
            .catch((err)=>{
                setErrorMessage(err?.response?.data?.msg ? err.response.data.msg : 'Request Failed. Please Try Again!!')
                setisShowing(true)
                console.log("post config error ", err)
                setSubmitting(false)
                setisLoading(false)

        })
    }

    const resetConfig = () => {
        // todo: do we need to do anything here?
        console.log("reset form ")        
    }

    const handleClose = () => {
        setisShowing(false)
        setErrorMessage('')
    }

    const handleCloseReject = () => {
        setisShowing(false)
        setErrorMessage('')
    }

    const renderSection = (values,key) => {
        console.log('section key ',key)
        let section = _.find(props.config.sections,{'key':key})
        let name  = section.key
        let sectionValue = values[key]
        
        console.log('section name ',name)
        if(section){    
            if(section.multi){
                return (
                    <FieldArray
                        key={`group-section-${section.key}`}
                        name={name}
                        render={arrayHelpers => (
                            <>
                            {sectionValue.map((s,i)=>(
                                <Section key={"section-"+section.key+'-'+i} name={name} section={section} index={i} arrayHelpers= {arrayHelpers}/>
                            ))}
                            {(sectionValue.length === 0 || name == 'parkingFloor' || name === 'subLocations') &&
                                <Button variant="contained" color="secondary" className={classes.addButton} startIcon={<PlusOneIcon />}
                                    onClick={(e) => arrayHelpers.push(getEmptySection(section))}
                                >
                                    {section.title}
                                </Button>                                
                            }
                            </>
                        )}
                    />
                    )
                
            }else{
                return <Section key={"section-"+section.key} name={section.key} section={section} />
            }
        }else{
            return(<></>)
        }
    }

    return(
        <>                
            {loading && <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>}
            {!loading && <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} 
                classes={{expanded:classes.expanded}}>
                    <Typography className={classes.panelHeading}>{props.config.title}</Typography>
                    <Typography className={classes.panelSubheading}>{props.config.help}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{root:classes.panelRoot}}>  
                    <Formik
                    onSubmit={saveConfig}
                    onReset={resetConfig}
                    initialValues={props.values}        
                    >
                    {({ values }) => (
                        <Form style={{flex:1}}>
                            {Object.keys(values).map((key) => (
                                renderSection(values,key)
                            ))}            
                            <div className={classes.panelAction}>
                                {/* hack: normally the <AccordionActions> are outside <AccordionDetails>
                                    but in this case we need the form to encapsulate the buttons
                                    If we move the form outside the <Accordion> the mui css for accordions 
                                    stops working 
                                */}
                                <AccordionActions>                
                                    <Button size="small" type="reset">Cancel</Button>
                                    <Button size="small" color="primary" type="submit"> Save </Button>
                                </AccordionActions>                   
                            </div> 
                        </Form>
                    )}
                    </Formik>
                </AccordionDetails>
            </Accordion>}

            { errorMessage !=='' &&
                <Dialog open={isShowing} onClose={handleClose} 
                    fullWidth={true}
                    fullScreen={fullScreen}
                >
                
                <DialogContent color="secondary">
                        <Typography variant={"body1"}> 
                            {errorMessage}
                        </Typography>                                                                                    
                </DialogContent>
                <DialogActions>
                    <Box display="inline-block">
                        <Button onClick={handleCloseReject} variant="outlined"  className={classes.button}>
                            OK
                        </Button>                                     
                    </Box>
                </DialogActions>
                </Dialog>
            }
        </>
    )
}

/**
 * Parent component for the configurations
 * 
 * @param {*} props 
 */
const ConfigPage = (props) => {
    const [config, setConfig] = useState([])
    const [values, setValues] = useState({})
    const [locState, setLocState] = useState({});
    const classes = useStyles()

    const location = useLocation();
    const params = queryString.parse(location.search);

    const qLoc = { id: parseInt(decode(params.id)[0]), name: decodeURIComponent(params.name) };
    
    console.log('form data',values)
    useEffect(() => {
        //todo: data to come from backend
        
        let tempValues  = {}
        
        API.get(props.url, {params:  {
              id: qLoc.id
        }})
        .then((res) => {
            console.log("default config",res.data.data)
            setConfig(res.data.data.configs)
            setLocState(res.data.data.loc)
            res.data.data.configs.map((config) => {
                // if this is a dummy section no values
                if(!config.isDummy){
                    tempValues[config.key] = {}
                    config.sections.map((section) => {                        
                        if(section.multi){
                            tempValues[config.key][section.key] = []
                            section.multiValues.map(val => {
                                tempValues[config.key][section.key].push(val)
                            })
                        }else{
                            tempValues[config.key][section.key] = {}
                            section.fields.map((field) => {
                                tempValues[config.key][section.key][field.key] = field.value
                            })
                        }
                    })
                    console.log("set the values to",tempValues)                    
                }                
            })
            setValues(tempValues)        
        })
        .catch((err) => {
            // got an error
            console.log("configuration api error")
        })

    }, [setConfig])

    const getKey = () =>{
        let index=0
        let key = null
        while(!key){
            if(_.findIndex(config,{key:'new'+index}) === -1)
                key = 'new'+index
            index++
        }
        return key
    }

    const addConfig = (e) =>{
        console.log('add config')
        let key = getKey()
        let newConfig = {
            sections:[],
            title:`New ${props.configAdd}`,
            key:key,
            help:'',
            url:config[0].url,
            add:true
        }
        let newValue = {[key]:{}}
        // check for any required sections
        config[0].sections.map((section)=>{
            console.log('section',newValue,section)            
            newValue[key][section.key] = section.multi ? [getEmptySection(section)] : getEmptySection(section)
            let sectionDup = _.cloneDeep(section)
            sectionDup.fields.map((val)=>{
                if(val.disabled)
                    val.disabled=false;
            })
            newConfig.sections.push(sectionDup)
                                  
        })
        console.log('new values', newValue);
        //todo: push values to config
        setConfig([...config,newConfig])
        setValues({...values,...newValue})
    }

    const handleConfigSave = (config,key,resData) => {
        console.log("config saved",key,config,resData)
        // update values
        if(props.url==='ratemodel'){
            let tempValues  = {}
            setConfig(resData.data)
            resData.data.map((config) => {
                    // if this is a dummy section no values
                    if(!config.isDummy){
                        tempValues[config.key] = {}
                        config.sections.map((section) => {                        
                            if(section.multi){
                                tempValues[config.key][section.key] = []
                                section.multiValues.map(val => {
                                    tempValues[config.key][section.key].push(val)
                                })
                            }else{
                                tempValues[config.key][section.key] = {}
                                section.fields.map((field) => {
                                    tempValues[config.key][section.key][field.key] = field.value
                                })
                            }
                        })
                        console.log("set the values to",tempValues)                    
                    }                
                })
                setValues(tempValues)
        }else{
            setValues({...values,...{[key]:config}})
        }
    }

    return(
        <div>            
            <ContainerHeader title={props.title+" - "+qLoc.name} match={props.match}/>    
            <div className="row">
                {props.configAdd &&
                <div className='col-12'>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        startIcon={<PlusOneIcon />}
                        onClick={addConfig}
                    >
                        {props.configAdd}
                    </Button>
                </div>
                }                
               
                <div className='col-12'>
                    {values && Object.keys(values).length>0 && 
                    <>
                        {config.map((conf) => { 
                            if(!conf.isDummy)
                                return(<Config 
                                    key={"config-"+conf.key} 
                                    config={conf} 
                                    locId={qLoc.id} 
                                    vehicleTypes={locState?.vehicle_types} 
                                    values={values[conf.key]}
                                    onSave={handleConfigSave}
                                    />)
                            
                            })}
                    </>
                    }
                </div>                
            </div>
        </div>
    )    
}

export default ConfigPage
import React from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import Divider from '@material-ui/core/Divider'

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1)
  }

};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return '#/';
  } else {
    return '#/' + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({title, match,style,textStyle,subText, additionalContent}) => {
  const path = match?.url?.substr(1); // smit: changed this as path showed varaible name :report
  let subPath = path?.split('/'); 
  subPath?.shift()
  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" style={style} >
      {additionalContent ?
        <div
          className="row"
          style={{minWidth:"75%", margin:0}} 
        >
          <div className="d-sm-flex  align-items-sm-center">
            <h2 className="title mb-3 mb-sm-0" style={{textAlign:'center'}} >{title}</h2>
          </div>
          <Divider orientation="vertical" flexItem style={{ backgroundColor: '#ddd', margin: '0 5px 0 20px' }} />
          {additionalContent}
        </div> : 
        <h2 className="title mb-3 mb-sm-0" style={textStyle} >{title}</h2>
      }
      <Breadcrumb className="mb-0" tag="nav">
        {subPath?.map((sub, index) => {
            return <BreadcrumbItem active={subPath.length === index + 1}
                tag="span" key={index}>{getDisplayString(sub)}</BreadcrumbItem>                                   
          }
        )}
        {
          subText&&<h2 className="title mb-3 mb-sm-0" style={textStyle} >{subText}</h2> 
        }
      </Breadcrumb>
    </div>
  )
};

export default ContainerHeader;


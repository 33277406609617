import React, { useState,useEffect, useRef } from 'react'
import ContainerHeader from 'components/ContainerHeader'
import CardBox from 'components/CardBox'
import {Box,Button, Typography,LinearProgress} from '@material-ui/core'
import { Form, Formik, connect } from "formik";
import FormField from "components/FormField";

import * as Yup from "yup";
import { makeStyles} from '@material-ui/core/styles'
import API from '../../../../../util/Api'
import _ from 'lodash'
import JsonView from 'components/JsonViewer'

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const validations = Yup.object().shape({
    type: Yup.object().required("Required"),
    location: Yup.object().required("Required"),
    guard: Yup.object().required("Required")
});

const ConfigField = (props) =>{
    let field = _.cloneDeep(props.field)
    let values = props.values
    let locations = props.locations
    
    if (field.dependency) {
        let fieldValue = field.dependency ? values[field.dependency] ? values[field.dependency].id : null : null;
        if (field.key === 'location') {
            let selectedType = values['type'];
            if(selectedType){
                const fieldOptions =   (fieldValue === 'parkingLoc') ? locations.parkingLoc : (fieldValue === 'valetLoc') ? locations.valetLoc : []
                field.options = [...fieldOptions];
            } else{
                field.options = [];
            }

        } else if(field.key === 'guard'){
            const fieldLabel = (fieldValue === 'valetLoc') ? 'Valet Name' : field.title;
            field.title = fieldLabel;

            let selectedLocation = values['location'];

            if (selectedLocation) {
                const locList = (fieldValue === 'parkingLoc') ? locations.parkingLoc : (fieldValue === 'valetLoc') ? locations.valetLoc : [];

                const selectedLoc = locList.find(loc => loc.id == selectedLocation.id);
                const fieldOptions = selectedLoc ? selectedLoc.guards : [];
                field.options = [...fieldOptions];
            } else {
                field.options = [];
            }
        }

        return(
            <div className="col-md-3 col-12" key={`field-${props.field.key}`}>
                <FormField
                    field={{
                        ...field,
                        ...{name:field.key}
                    }}
                />
            </div>
        );
    }

    return(
        <div className="col-md-3 col-12" key={`field-${props.field.key}`}>
            <FormField  
                field={{
                    ...field,
                    ...{name:field.key}
                }}
            />
        </div>
    );
}
const EZField = connect(ConfigField)

const DeviceDataForm = connect((props) =>{
    const locations = props.locations;
    const previousValues = useRef(props.formik.values);
    
    useEffect(() => {
        console.log("Formik___values", props.formik.values)
        if(props.formik.values.type !== previousValues.current.type){
            props.formik.setFieldValue('location', null);
            props.formik.setFieldValue('guard', null);
        } else if (props.formik.values.location !== previousValues.current.location) {
            props.formik.setFieldValue('guard', null);
        }
        props.handleReset();
        previousValues.current = props.formik.values;
    }, [props.formik.values]);

    return (
        <CardBox styleName="col-12" >
            <Form>
                {/* FIRST ROW */}
                <div className="row">
                    {props.config.fields.map((field) => (
                        <EZField 
                            field={field}
                            key={`field-${field.key}`}
                            values = {props.formik.values}
                            locations = {locations}
                        />
                    ))}
                </div>
                {/* BUTTONS */}
                <div className="row mt-2">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <Box mt="20px">
                            <Box mr="10px" display="inline">
                                <Button
                                variant="contained"
                                color="primary"
                                mr="5px"
                                type="submit"
                                >
                                Submit
                                </Button>
                            </Box>
                            <Box mr="10px" display="inline">
                                <Button variant="outlined" color="secondary" type="reset">
                                Reset
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Form>
        </CardBox>
    );
});

const DeviceData = (props)=>{
    const classes = useStyles()
    const [locations, setLocations] = useState({parkingLoc: [], valetLoc: []});
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [guard,selectedGuard] = useState(null)
    const [showProgress,setShowProgress] = useState(false)
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [jsonData,setJsonData] = useState({
        'config':null,
        'accessList':null
    })

    const getFieldValues = (fields) => {
        const values = fields.reduce((obj, item) => {
            return { ...obj, [item.key]: item.value };
        }, {});
        return values;
    }

    useEffect(()=>{
        API.get("deviceDataConfig?guard=true").then(response=>{
            // console.log("deviceDataConfig____", response.data.data);
            const data = response.data.data;
            const values = getFieldValues(response.data.data.config.fields);
            setInitialValues(values);
            setConfig(data.config);
            setLocations((initialState) => ({...initialState, parkingLoc: data.parkingLoc, valetLoc: data.valetLoc}));
        }).catch(error=>{})
    },[]);

    const handleSubmit = (values) =>{
        setShowProgress(true)
        API.get("deviceData?guardId="+values.guard.id+"&locationId="+values.location.id+"&type="+values.type.id).then(response=>{
            console.log("getDeviceData-response",response.data.data)
            setShowProgress(false)
            if(values.type.id === 'parkingLoc'){
                setJsonData({
                    config:response.data.data.locConfig,
                    accessList:response.data.data.accesslist
                })
            } else if(values.type.id === 'valetLoc'){
                setJsonData((initialState) => ({ ...initialState,
                    config:response.data.data.locConfig
                }));
            }
        }).catch(error=>{
            setShowProgress(false)
        })
    }
    const handleReset = ()=>{
        setJsonData({
            'config':null,
            'accessList':null
        });
    }
    return(
        <>
            <ContainerHeader title={'Device Data'} match={props.match}/>
            {
                <div>
                    {config && initialValues &&
                        <Formik
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            initialValues={initialValues}
                            validationSchema={validations}
                            resetInitalValue={true}
                        >
                            <DeviceDataForm
                                config={config}
                                handleReset={()=>{handleReset()}}
                                locations={locations}
                            />
                        </Formik>
                    }
                </div>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {   jsonData.config&&
                <JsonView name={'Guard Config'} jsonObject={jsonData.config}/>
            }
            {
                jsonData.config && jsonData.config.length==0 &&
                <Typography style={{padding:15}} >{(selectedLoc &&guard)?'No Config data for '+selectedLoc.name:''}</Typography>
            }
            {
                jsonData.accessList&&
                <JsonView name={'Guard Access List'} jsonObject={jsonData.accessList}/>
            }
            {
                jsonData.accessList && jsonData.accessList.length==0 &&
                <Typography style={{padding:15}} >{(selectedLoc &&guard)?'No Access List data for '+selectedLoc.name+' - '+guard.name:''}</Typography>
            }
        </>
    )
}
export default DeviceData
import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { EZLineChart, PaymentModesPieChart } from "components/Chart"; 
import _ from "lodash";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import CallMadeIcon from '@material-ui/icons/CallMade';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { AccountBox, Cancel, PersonPin } from "@material-ui/icons";


const BasicReportCharts = (props) => {
    const [availableChartPeriods] = useState(['hourly']);
    const [selectedChartPeriod, setSelectedChartPeriod] = useState('hourly');
    
    const handleStateChange = (event) => {
        setSelectedChartPeriod(event.target.value);
    };
    return (<>
        {Object.keys(props.headerSummary).length > 0 &&
            <Grid container className="col-12 p-0">
                <Grid item  className="jr-card col-lg-12  col-md-12 p-0 mb-5" style={{borderRadius:10}}  >
                {/* BIKE PAYMENT MODE */}
                <Grid container  direction="column" className="paymentModeContainer">
                    <Grid container className="paymentModeHeader" >
                    <Grid container direction="row" className="paymentModeHeaderContent mt-1 col-12" spacing={1}>
                        { Object.entries(props.headerSummary)
                            .filter(([key, value]) => key !== "online" && key !== "cash")
                            .map(([key, value]) => (
                            <Grid item xs={6} sm={2} md={2}>
                            <div className="d-flex flex-column align-items-center" style={{maxWidth: 'max-content'}}>
                                <div className="d-flex align-items-center" style={{fontSize:'1.5rem'}}>
                                    {
                                        key === 'pickups' ? <CallReceivedIcon  className="headericons" />
                                        : key === 'returns' ? <CallMadeIcon  className="headericons" />
                                        : key === 'active' ? <BeenhereIcon  className="headericons" />
                                        : key === 'valet_recall' ? <PersonPin className="headericons" />
                                        : key === 'user_recall' ? <AccountBox  className="headericons" />
                                        : key === 'cancelled' && <Cancel  className="headericons" />
                                    }
                                    {  new Intl.NumberFormat('en-IN').format(value ?? 0)}
                                </div>
                                <div>{ key?.split('_').join(' ').toUpperCase() }</div>
                            </div>
                            </Grid>
                        ))
                        }
                    </Grid>
                    </Grid>
                    <Grid container className="paymentBody col-12 p-0">
                    <Grid item direction='column' style={{borderRight:'2px solid rgb(222, 226, 230)',padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center mb-4">
                        <Grid container className="align-items-center mb-3" direction='row' >
                        <Typography  variant={'h6'} style={{fontSize:'16px'}}>Pickups/Returns Trend</Typography>
                            { selectedChartPeriod && 
                            <FormControl  FormControl className="col-md-2  stateSelection " style={{ marginLeft: "auto" }}>
                                    <InputLabel >PERIOD</InputLabel>
                                    <Select
                                        name={'period'}
                                        value={selectedChartPeriod}
                                        onChange={(e) => handleStateChange(e)}
                                        input={<Input id="ageSimple1" />}
                                    >
                                        {availableChartPeriods.map( period => (
                                            <MenuItem value={period}>
                                                {period}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>                                           
                        <EZLineChart data={props.charts.line_chart} height={300}/>
                    </Grid>
                    <Grid container direction="column"  className="col-xl-6 col-lg-6 col-md-12 pb-3 d-flex align-items-center justify-content-center">
                    <>
                        <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18}}>Payment Modes Trend</Typography>
                        <Grid > 
                            <PaymentModesPieChart data={props.charts.pie_chart} height={300} width={240}/>  
                        </Grid>
                        <Grid item style={{ width: '100%',marginTop:10 }}>
                            <div className="d-flex flex-wrap justify-content-center w-100">
                                {props.charts.pie_chart.data.map((paymentMode, i) => {
                                return (
                                <>
                                    <div key={i} className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                        <div 
                                            style={{ width: '12px', height: '12px', backgroundColor: paymentMode.color, marginRight: '5px', borderRadius: '50%' }} 
                                        />
                                        <span>{paymentMode.label.toUpperCase()} - {new Intl.NumberFormat('en-IN').format(paymentMode.data)}</span>
                                    </div>
                                </>     
                                )
                                })}
                                <div className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                <div style={{ width: '12px', height: '12px', backgroundColor: '#999999', marginRight: '5px', borderRadius: '50%' }} />
                                <span>TOTAL - {new Intl.NumberFormat('en-IN').format(props.charts.pie_chart.totalTrns)}</span>
                                </div>
                            </div>
                        </Grid>
                    </>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        }
    </>
    )
}

export default BasicReportCharts
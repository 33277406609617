import React, { useEffect, useState } from "react";
import { Typography, IconButton } from '@material-ui/core'
import * as Yup from "yup";
import _ from "lodash";
import { makeStyles } from '@material-ui/core/styles'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CropFreeIcon from '@material-ui/icons/CropFree';
import SnackBar from "components/SnackBar";
import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import Canvas from "components/Canvas"
import EZForm from "components/EZForm";
import API from "util/Api";

const useStyles = makeStyles((theme) => (
    {
        label: {
            color: theme.palette.primary.main,
            marginTop: '16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        headerStyle: {
            textAlign: 'end',
            marginBottom: 0
        },
        terminal: {
            background: '#263647',
            boxShadow: 'inset 0px 0px 5px black',
        },
        terminalText: {
            color: '#fff'
        },
        cardStyle: {
            padding: 0
        },
        preTag: {
            padding: 10,
            marginBottom: 0,
            width: '100%',
            margin: '0px !important'
        },
        btnOlder: {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 5,
            borderRadius: 25,
            fontSize: 10,
            marginTop: '-16px'
        }
    }
))

const ParkingSlots = (props) => {
    const handle = useFullScreenHandle()
    const classes = useStyles()
    const [fields, setFields] = useState();
    const [floor, setFloor] = useState();
    const [scale, setScale] = useState(1);
    const [canvaStyle, setCanvaStyle] = useState({ height: '500px' });
    const [formData, setFormData] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const validations = Yup.object().shape({
        location: Yup.object().required("Required"),
    });

    const handleSubmit = (data, { setSubmitting }) => {
        setFloor(undefined);
        API.post("parkingFloorMap", JSON.stringify(data))
            .then((res) => {
                setFloor(res.data.data);
                setSubmitting(false);
                setFormData(data);
            })
            .catch((err) => {
                setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleChange = (formik) => {
        console.log('handleChange - ', formik)
        const values = formik.values;
        if (values.location && !_.isEqual(values.location.floors, fields.floor.options)) {
            formik.setFieldValue('floor_id', '');
            const config = _.cloneDeep(fields);
            config.floor.options = values.location.floors;
            setFields(config);
        }
    }

    const screenChange = (fullScreen) => {
        if (fullScreen) {
            setCanvaStyle({ height: '100vh' })
        } else {
            setCanvaStyle({ height: '500px' })
        }
    }

    useEffect(() => {
        API.get("parkingFloorMap")
            .then((res) => {
                setFields(res.data.data.fields);
            })
            .catch((err) => {
                console.error("error", err);
            });
    }, []);

    useEffect(() => {
        if (formData) {
            const intervalId = setInterval(() => {
                API.post("parkingFloorMap", JSON.stringify(formData))
                    .then((res) => {
                        setFloor(res.data.data);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }, 60000); // refresh every 1 minut

            return () => clearInterval(intervalId);
        }
    }, [formData])


    return (
        <>
            <ContainerHeader title={`Parking Slots`} match={props.match} />
            {fields && <EZForm
                fields={Object.values(fields)}
                validationSchema={validations}
                handleChange={handleChange}
                onSubmit={handleSubmit}
            />}

            <div className="row" style={{ display: floor ? 'block' : 'none' }}>
                <CardBox styleName="col-12" headingStyle={classes.headerStyle} heading={
                    <>
                        <Typography style={{ float: 'left', textAlign: 'left' }} variant={'h6'} component={'h6'}>Parking Map - {floor?.location} - {floor?.floor_no}</Typography>
                        <div>
                            {document.fullscreenEnabled &&
                                <IconButton
                                    onClick={() => {
                                        if (!handle.active) {
                                            handle.enter()
                                        } else {
                                            handle.exit()
                                        }
                                    }}
                                    color="primary"
                                    fontSize="large">
                                    <CropFreeIcon />
                                </IconButton>}
                            <IconButton color="primary" onClick={() => {
                                setScale((prevScale) => Math.min(prevScale + 0.2, 2.4))
                            }}><ZoomInIcon fontSize="large" /></IconButton>
                            <IconButton color="primary" onClick={() => {
                                setScale((prevScale) => Math.max(prevScale - 0.2, 1))
                            }}><ZoomOutIcon fontSize="large" /></IconButton>
                        </div>
                    </>
                }>
                    <FullScreen handle={handle} onChange={screenChange} >
                        <div style={{ overflow: 'auto', textAlign: 'center' }}>
                            <div style={{
                                transition: 'transform 0.3s ease-in-out', // Smooth zoom effect
                                transformOrigin: 'top center',
                                display: 'inline-block',
                                textAlign: 'center',
                                ...canvaStyle
                            }}>
                                <Canvas
                                    floor={floor}
                                    scale={scale}
                                    blink={true}
                                    style={{ height: '100%' }}
                                />
                            </div>
                        </div>
                    </FullScreen>
                </CardBox>
            </div>
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )
}
export default ParkingSlots;
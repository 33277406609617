import React from 'react';
// import {getReports} from './data';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';
import moment from 'moment'
import RenewFilter from './RenewFilter'
import ReportTable from 'components/ReportTable/index';
import RenewDialog from './RenewDialog';
import PopUpDialog from 'components/PopUpDialog';
import { saveAs } from "file-saver";

class Subscriptions extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            match: props.match,
            formInputs:{},
            table:{},
            locations: {},
            clients: {},
            subscription_type:["User","Client"],
            appState:'loading',
            errorMessage: '',
            subscription:{'start':'','end':'','amount':0},
            subscriptionVehicle:{autotagId:null,vehicleNo:null,locId:null},
            selectedSusbcription:[],
            isShowing:false,
            loading:false,
            message:'',
            open:false,
            dialogContent:'',
            dialogAgreeBtnTxt:'',
            dialogDisAgreeBtnTxt:'',
            isBtnDisabled:false,
            dialogHeader:'',
            dialogType:'',
            locationName:''
        }
        this.getData = this.getData.bind(this)
        this.renewSubscription = this.renewSubscription.bind(this)
        this.updteVehicleNo = this.updteVehicleNo.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.renewSubscriptionData = this.renewSubscriptionData.bind(this)
        this.updteVehicleNoData = this.updteVehicleNoData.bind(this)
        this.onEnable = this.onEnable.bind(this)
        this.updateSubscription = this.updateSubscription.bind(this)
        this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this)
        this.updateAmount = this.updateAmount.bind(this)
        this.handleDialogCallback = this.handleDialogCallback.bind(this)
        this.onEdit=this.onEdit.bind(this)
        this.downloadInvoice = this.downloadInvoice.bind(this)
    }  

    componentDidMount(){         
        API.get('renewSubscriptionData')
        .then((res) => {
            // received data        
            console.log('rcvd data',res.data.data)
            this.setState({ appState:'active',locations: res.data.data.locations,clients:res.data.data.clients, errorMessage: ""})
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please try again"});
        })
    }
    getData(params){
        console.log("getData",params)

        this.setState({appState:'waiting',locationName:params.name})
        API.get('subscriptions?data='+JSON.stringify(params))
        .then((res)=> {
            // rcvd reports data
            this.setState({table:res.data.data,appState:'active', errorMessage: "",formInputs:params})
        })
        .catch((err)=> {
            this.setState({errorMessage: "Please Try Again"});
        })
    }
    renewSubscription(params){
        console.log(params)
        let subscription = _.cloneDeep(params);
        const oneMonthAgo = moment(moment().subtract(1, 'M'), 'DD-MM-YYYY HH:mm');
        const endDate = moment(params.end, 'DD-MM-YYYY HH:mm');
        if (endDate.isBefore(oneMonthAgo)) {
            const dateObj = moment();
            subscription.start = moment(dateObj,'DD-MM-YYYY HH:mm').startOf('day').toDate();
            subscription.end = moment(dateObj,'DD-MM-YYYY HH:mm').add(1,'M').endOf('day').toDate();
            subscription.minStart = moment(dateObj,'DD-MM-YYYY HH:mm').subtract(1,'M').startOf('day').toDate();
        } else {
            if (endDate.format('HH:mm') === '11:59') {
                // If time is 11:59, add 1 day and set the time to 00:00 (start of the day)
                subscription.start = moment(params.end,'DD-MM-YYYY HH:mm').add(1, 'days').startOf('day').toDate(); // This will give you the next day at 12:00 AM
            } else {
                // Otherwise, add 1 sec to the given time
                subscription.start = moment(params.end,'DD-MM-YYYY HH:mm').add(1, 'second').toDate(); // This will give you the same day with 1 minute added
            }
            subscription.end = moment(params.end,'DD-MM-YYYY HH:mm').add(1,'M').endOf('day').toDate();
            subscription.minStart = moment(params.end,'DD-MM-YYYY HH:mm').subtract(1,'M').toDate();
        }
        this.setState({subscription:subscription, isShowing: true,dialogType:'renew'})
    }
    updteVehicleNo(vechNo){
        const vechData = {autotagId:vechNo.auto_tag_id_hidden,vehicleNo:vechNo.vehicle_no,locId:vechNo.location_id_hidden}
        this.setState({subscriptionVehicle:vechData,isShowing: true,dialogType:'updateVehicle'})
    }
    updteVehicleNoData(data){
        API.post("updateSubscriptionVehicle", JSON.stringify({autoTagId:data.autotagId,vehicleNo:data.vehicleNo,locationId:data.locId}))
            .then((res)=> {
                
                let activeTable = _.cloneDeep(this.state.table.active.table)
                let list = _.filter(activeTable,obj=>obj.auto_tag_id_hidden==data.autotagId)
                let updatedList = []
                _.forEach(list,obj=>{
                    obj.vehicle_no = data.vehicleNo
                    updatedList.push(obj)
                })
                let newList = _.unionBy(updatedList,activeTable,'plus_id_hidden')
                let newTable = _.cloneDeep(this.state.table)
                newTable.active.table = newList
                this.setState({message:res.data.msg,loading:false,table:[]})
                this.setState({table:newTable})
                var self = this;
                setTimeout(function(){
                    self.handleClose()
                },3000);
            })
            .catch((err)=>{
                this.setState({errorMessage:err.response.data.msg,loading:false});
            })
    }
    handleClose(){
        this.setState({isShowing: false,errorMessage:'',message:'',header:''})

    }
    onEnable(params,checked){
        var text = checked ? 'Are you sure you want to activate subscription ?' : 'Are you sure you want to deactivate subscription?'
        var header = checked ? 'Activate Subscription' : 'Deactivate Subscription'
        console.log('asdasd',params,checked)
        this.setState({selectedSusbcription:params,open:true,dialogAgreeBtnTxt:'Update',dialogDisAgreeBtnTxt:'Cancel',dialogContent:text,dialogHeader:header})
    }
    onEdit(params){
        console.log("onEdit-",params)
        let subsType=params.update_type_hidden // subscription type
        if(subsType==='client_subscription'){
            const clientData = {plcs_id:params.plcs_id_hidden, vehicle_limit:params.vehicle_limit_hidden, client_name:params.name,client_id:params.client_id_hidden,locationId:params.location_id_hidden}
            this.setState({subscription:clientData, isShowing: true,dialogType:'update_client_subscription',header:'Update Client Subscription'})

        }else{
            let id = (params.pcu_id_hidden)?params.pcu_id_hidden:params.plus_id_hidden
            // this.props.history.push({
            //   pathname: `/app/subscriptions/update-subscription/${id}/${subsType}`,
            //   state: {...params, location_name:this.state.locationName},
            //   target:'_blank'
            // });
            window.open(`/app/subscriptions/update-subscription/${id}/${subsType}/${this.state.locationName}/${params.location_id_hidden}`, '_blank')
        }
        
    }
    handleConfirmDialogClose(){
        this.setState({selectedSusbcription:{},open:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:''})
    }
    updateSubscription(){
        this.setState({isBtnDisabled:true});
        API.post("updateSubscriptionStatus", JSON.stringify(this.state.selectedSusbcription))
            .then((res)=> {
                this.setState(initails=>({...initails,selectedSusbcription:{},open:false,isBtnDisabled:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:'',table:res.data.data}))
            })
            .catch((err)=>{
                this.setState({errorMessage:err.response.data.msg,open:false,isBtnDisabled:false});
                console.log("post config error ", err)
      })
    }
    updateClientSubscription(inputs){
        console.log("updateClientSubscription",inputs)
        API.put("subscription", {...inputs,type:'client'}).then((res) => {
            this.setState({message:res.data.msg,loading:false,table:res.data.data})
                var self = this;
                setTimeout(function(){
                    self.handleClose()
                },3000);
        }).catch((err) => {
            console.log("post config error ", err);
        });
    }
    downloadInvoice(params){
        API.get("subscriptionInvoice", {params:params}).then((res) => {
            saveAs(res.data.data);
        }).catch((err) => {
            console.log("post config error ", err);
        });
    }

    renewSubscriptionData(params){
        API.post("subscriptionsRenew", JSON.stringify(params))
            .then((res)=> {
                this.setState({message:res.data.msg,loading:false,table:[]})
                this.setState({table:res.data.data})
                var self = this;
                setTimeout(function(){
                    self.handleClose()
                },3000);
            })
            .catch((err)=>{
                this.setState({errorMessage:err.response.data.msg,loading:false});
                console.log("post config error ", err)
            })
    }
    updateAmount(params){
        this.setState({subscriptionData:params,isShowing: true,dialogType:'updateAmount',header:'Update NFC amount'})
    }
    handleDialogCallback(params,type){

        if(type==='updateAmount'){
            if(parseInt(params.amount)>=0){
                const postData = {
                    plus_id:this.state.subscriptionData.plus_id_hidden,
                    amount:params.amount,
                    formSelectedClient:this.state.formInputs.client
                }
                console.log("handleDialogCallback-updateAmount",postData)
                API.post("updateNFCSubscriptionAmount", JSON.stringify(postData))
                .then((res)=> {
                    this.setState({message:res.data.msg,loading:false,table:[]})
                    this.setState({table:res.data.data})
                    var self = this;
                    setTimeout(function(){
                        self.handleClose()
                    },3000);
                    
                })
                .catch((err)=>{
                    this.setState({errorMessage:err?.response?.data?.msg,loading:false});
                    console.log("post config error ", err)
                })
            }else{
                this.setState({errorMessage:'Amount should be grater than 0',loading:false});
            }
            
        }
        console.log("handleDialogCallback",params,type)
    }
    
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<React.Fragment> {this.renderPage()} </React.Fragment>)                
        }
    }
    render() {
        return (        <React.Fragment> 
            {this.renderState(this.state.appState)} </React.Fragment>)
    }

    renderPage(){
        return(
            <div>
                <ContainerHeader title="Renew Subscriptions" match={this.props.match} />    
                <div className="row">
                    <CardBox styleName="col-12">
                        <RenewFilter 
                            locations={this.state.locations} 
                            clients = {this.state.clients}
                            getReportData={this.getData} 
                            subscription_type={this.state.subscription_type}
                            />
                    </CardBox>
                </div>
                { this.state.errorMessage!== '' && <h3 className="row"> { this.state.errorMessage } </h3> }
                    {this.state.appState==='waiting' 
                    ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                    : <React.Fragment>
                        {
                        Object.keys(this.state.table).length >0 &&
                            <ReportTable downloadCSVBtn onEnable={this.onEnable} data = {this.state.table} renewSubscription = {this.renewSubscription} downloadInvoice={this.downloadInvoice} onEdit={this.onEdit} updateAmount={this.updateAmount} />
                        }</React.Fragment>
                    }
                  <RenewDialog 
                    handleClose={this.handleClose} 
                    isShowing={this.state.isShowing} 
                    loading={this.state.loading} 
                    errorMessage={this.state.errorMessage} 
                    subscription={(this.state.dialogType==='renew'|| this.state.dialogType==='updateAmount' || this.state.dialogType==='update_client_subscription')?this.state.subscription:this.state.subscriptionVehicle} 
                    renewSubscriptionData={this.renewSubscriptionData} updateVehicleData={this.updteVehicleNoData}
                    handleDialogCallback={this.handleDialogCallback} 
                    updateClientSubscription={this.updateClientSubscription.bind(this)}
                    message={this.state.message} 
                    dialogType={this.state.dialogType}
                    header={this.state.header}
                  /> 
                  

                  <PopUpDialog 
                    open={this.state.open}
                    header={this.state.dialogHeader}
                    content ={this.state.dialogContent}
                    agreeBtnTxt ={this.state.dialogAgreeBtnTxt}
                    isBtnDisabled={this.state.isBtnDisabled}
                    disAgreeBtnTxt={this.state.dialogDisAgreeBtnTxt}
                    agreeClicked ={this.updateSubscription}
                    handleDialogClose={this.handleConfirmDialogClose}
                />    
            </div>
        )
    }
}
export default Subscriptions;
